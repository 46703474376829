import { redirect } from 'react-router-dom';

// Own
import { ROUTE_APP_DASHBOARD } from '../constants/routes';
import { getToken } from '../helpers/session-utils';


const GuestGuard = ({user}) => {
  const token = getToken();
  if (user || token) {
    return redirect(ROUTE_APP_DASHBOARD);
  }
  return null;
}

export default GuestGuard
