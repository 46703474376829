import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';
import {
  setAllRecords,
  setAllRecordsError,
  setAllRecordsSuccess
} from '../actions';
import {
  FILE_MANAGER_GET_FILE_LIST,
  FILE_MANAGER_UPLOAD_FILE,
  FILE_MANAGER_DELETE_FILE,
  GET_FILE,
} from '../constants';

import {
  getFileListSuccess,
  getFileListError,
  uploadFileSuccess,
  uploadFileError,
  deleteFileSuccess,
  deleteFileError,
  clearStatus,
  getFileSuccess,
  getFileError
} from './actions';

const getFileListRequest = async (payload) => {
  const data = payload;
  const {
    ecosystemID = null,
    page = null,
    peerPage = null,
    orderBy = null,
    search = null,
  } = data;

  const request = await apiRequest({
    api: 'EcosystemsDataFilesRetriever',
    props: {
      ecosystemId: ecosystemID,
      page,
      peerPage,
      orderBy,
      search,
    },
  })
  .then((response)=> response)
  .catch((error) => error);

  return request;
}


function* getFileListItems({payload}) {
  const state = yield select();
  const loading = state?.files?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(getFileListRequest, payload);

    if (request.status) {
      yield put(getFileListSuccess(request.response));
      yield put(setAllRecords());
      yield put(setAllRecordsSuccess(request['all-records']));
    } else {
      yield put(getFileListError("Error al traer la lista de archivos"))
      yield put(setAllRecordsError(0));
    }

  } catch (error) {
    yield put(getFileListError(error));
    yield put(setAllRecordsError(0));
  }
}

export function* watchGetList() {
  yield takeEvery(FILE_MANAGER_GET_FILE_LIST, getFileListItems);
}

const uploadFileProcessAsync = async (payload) => {
  const result = await apiRequest({
    api: 'UploadFilesToEcosystem',
    props: {
      ecosystemId: payload.id,
      fileList: payload.files
    },
  })
  .then((response)=> response)
  .catch((error) => error);

  return result;
}


function* uploadFile({ payload }) {
  const {IDs, id } = payload;
  const state = yield select();
  const loading = state?.files?.loading;
  if (!loading) {
    return;
  }

  try {
    const processFilePayload = { files: IDs, id }
    const processFilesResponse = yield call(uploadFileProcessAsync , processFilePayload);
    if (processFilesResponse.status) {
      yield put(uploadFileSuccess());
    }else{
      yield put(uploadFileError('Error subiendo el archivo'));
    }
  } catch (error) {
    yield put(uploadFileError(error));

  }
}

export function* watchUploadFile() {
  yield takeEvery(FILE_MANAGER_UPLOAD_FILE, uploadFile);
}


const deleteFileRequest = async (payload) =>
   // eslint-disable-next-line no-return-await
   await apiRequest({
    api: 'DeleteFileFromEcosystem',
    props: {
      ecosystemId: payload.idEco,
      fileId: payload.fileId
    },
  })
  .then((response)=> response)
  .catch((error) => error);


function* deleteFile({ payload }){
  const state = yield select();
  const loading = state?.files?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(deleteFileRequest, payload);
    if (request.status) {
      yield put(deleteFileSuccess());
      yield put(clearStatus());
    } else {
      yield put(deleteFileError("Error al eliminar el archivo"))
    }
  } catch (error) {
    yield put(deleteFileError(error));
  }

}

export function* watchDeleteFile() {
  yield takeEvery(FILE_MANAGER_DELETE_FILE, deleteFile);
}

export function* getFile({payload}) {
  const state = yield select();
  const loading = state?.files?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'GetFilesDataById',
      props: payload ?? null,
    });
    if (request.status) {
      yield put(getFileSuccess(request.response));
    } else {
      yield put(getFileError(request.Error.errorCode));
    }
  } catch (error) {
    yield put(getFileError(error));
  }
}

export function* watchGetFile() {
  yield takeEvery(GET_FILE, getFile);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchUploadFile),
    fork(watchDeleteFile),
    fork(watchGetFile),
  ]);
}
