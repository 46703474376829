import {
  COMMENT,
  COMMENT_DELETE,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_ERROR,
  COMMENT_LIST,
  COMMENT_SUCCESS,
  COMMENT_ERROR,
  COMMENT_LIST_SUCCESS,
  COMMENT_LIST_ERROR,
} from '../constants';

const INIT_STATE = {
  loadingComments: false,
  error: '',
  commentsList: [],
};
const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case COMMENT:
      return {
        ...state,
        loadingComments: true,
        error: ''
      };
    case COMMENT_DELETE:
      return {
        ...state,
        loadingComments: true,
        error: ''
      };
    case COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        loadingComments: false,
        error: ''
      };
    case COMMENT_DELETE_ERROR:
      return {
        ...state,
        loadingComments: false,
        error: action.payload
      };
    case COMMENT_SUCCESS:
      return {
        ...state,
        loadingComments: false
      };
    case COMMENT_LIST_SUCCESS:
      return {
        ...state,
        loadingComments: false,
        commentsList: action.payload
      };
    case COMMENT_LIST_ERROR:
      return {
        ...state,
        loadingComments: false,
        error: action.payload
      };
    case COMMENT_LIST:
      return {
        ...state,
        loadingComments: true
      };
    case COMMENT_ERROR:
      return {
        ...state,
        loadingComments: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default reducer;
