import {
  MEETING_TYPE_LIST,
  MEETING_TYPE_LIST_SUCCESS,
  MEETING_TYPE_LIST_ERROR,
} from '../constants';


const INIT_STATE = {
  loading: false,
  error: '',
  meetingTypeList: [],
};

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case MEETING_TYPE_LIST:
      return {
        ...state,
        loading: true
      };
    case MEETING_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        meetingTypeList: action.payload
      };
    case MEETING_TYPE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
