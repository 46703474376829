import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  USER_LIST_GET_LIST,
  USER_LIST_GET_LIST_SUCCESS,
  USER_LIST_GET_LIST_ERROR,
  USER_CHANGE_ROL_PETITION_LIST,
  USER_CHANGE_ROL_PETITION_LIST_SUCCESS,
  USER_CHANGE_ROL_PETITION_LIST_ERROR,
  USER_CHANGE_ROL_PETITION_REJECT,
  USER_CHANGE_ROL_PETITION_REJECT_ERROR,
  USER_CHANGE_ROL_PETITION_REJECT_SUCCESS,
  USER_CHANGE_ROL_PETITION_APPROVAL,
  USER_CHANGE_ROL_PETITION_APPROVAL_ERROR,
  USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: '',
  users:  [],
  petitions:  [],
  status: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* DELETE USER */
    case DELETE_USER:
      return {
        ...state,
        loading: true,
        postId: action.payload,
        status: false,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: true,
      };

    case DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: false,
      };

    // GET USER LIST
    case USER_LIST_GET_LIST:
      return {
        ...state,
        loading: true,
        payload: action.payload,
        users: [],
      };

    case USER_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case USER_LIST_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: [],
      };

    // GET LIST ROL PETITIONS
    case USER_CHANGE_ROL_PETITION_LIST:
      return {
        ...state,
        loading: true,
        payload: action.payload
      }

    case USER_CHANGE_ROL_PETITION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case USER_CHANGE_ROL_PETITION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        petitions: action.payload,
      }

    // CHANGE ROL PETITION REJECT
    case USER_CHANGE_ROL_PETITION_REJECT:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case USER_CHANGE_ROL_PETITION_REJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_CHANGE_ROL_PETITION_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        successPetition: action.payload,
      };

    // CHANGE ROL PETITION APPROVAL
    case USER_CHANGE_ROL_PETITION_APPROVAL:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case USER_CHANGE_ROL_PETITION_APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        successPetition: action.payload,
      };

    default:
      return { ...state };
  }
};

export default reducer;
