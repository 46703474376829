// AUTH ACTIONS
export {
  basicLogin,
  basicLoginError,
  basicLoginSuccess,
  loginWithSocial,
  loginWithSocialError,
  loginWithSocialSuccess,
  register,
  registerSuccess,
  registerError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordError,
  loadUserInfo,
  loadUserInfoSuccess,
  loadUserInfoError,
  logout,
  logoutSuccess,
  logoutError,
  clear as authClear
} from './auth/actions';

// MENU ACTIONS
export {
  setContainerClassnames,
  addContainerClassnames,
  changeDefaultClassnames,
  changeSelectedMenuHasSubItems,
  clickOnMobileMenu,
} from './menu/actions';

// POST ACTIONS
export {
  post,
  postError,
  postSuccess,
  getPosts,
  getPostsError,
  getPostsSuccess,
  getPostSearch,
  setCurrentEcosystem,
  getPost,
  getPostSuccess,
  getPostError,
  setPostStatus,
  setPostStatusSuccess,
  setPostStatusError,
  deletePost,
  deletePostSuccess,
  deletePostError,
  updatePost,
  updatePostSuccess,
  updatePostError,
} from './posts/actions';

// ROLES ACTIONS
export {
  getRoleList,
  getRoleListSuccess,
  getRoleListError,
} from './roles/action';

// HELP ACTIONS
export {
  contact,
  contactSuccess,
  contactError
} from './contact/actions'

// TODO: Refactor this.
// ALL RECORDS
export {
  setAllRecords,
  setAllRecordsSuccess,
  setAllRecordsError,
} from './records/actions';

// COMMENTS
export {
  sendComment,
  getCommentList,
  commentDelete,
} from './comments/actions';

// REACTIONS
export {
  reactedTo,
  reactedToSuccess,
  reactedToError,
  getReactions,
  getReactionsSuccess,
  getReactionsError,
} from './reactions/actions';

// VOTE
export {
  voteAPost,
  voteAPostSuccess,
  voteAPostError
} from './votes/actions';

// MEETING
export {
  getMeetingTypeList,
  getMeetingTypeListSuccess,
  getMeetingTypeListError,
} from './meeting/actions';

// FILES
export {
  getFileList,
  uploadFile,
  deleteFile,
  getFileManagerListWithOrder,
  getFileManagerListSearch,
  getFile,
  getFileSuccess,
  getFileError,
  getAttachmentsList,
  getAttachmentsListSuccess,
  getAttachmentsListError,
  saveAttachment,
  saveAttachmentSuccess,
  saveAttachmentError,
  deleteAttachments,
  deleteAttachmentsSuccess,
  deleteAttachmentsError,
  createFolder,
  createFolderSuccess,
  createFolderError,
  clearStatus as fileClearStatus,
} from './files/actions';

// PROFILE
export {
  uploadProfilePicture,
  updateProfileData,
  changeRolPetition,
} from './profile/actions';

// SUBSCRIPTIONS
export {
  toSubscribe,
} from './preferences/actions';

// ECOSYSTEMS
export {
  getAllEcosystemsList,
  getAllEcosystemsSubscribersStatusList,
  getAllEcosystemsSubscribersList,
} from './ecosystems/action';

// DELETE USERS
export {
  deleteUser,
  getUserList,
  changeRolPetitionList,
  changeRolPetitionReject,
  changeRolPetitionApproval,
} from './users/actions';

// LOCALE
export {
  changeLocale,
} from './locale/actions';

// Notifications actions
export {
  markAsReadNotifications,
  markAsReadNotificationsSuccess,
  markAsReadNotificationsError,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsError,
} from './notifications/actions'

// FILES
export {

} from './files/actions'
