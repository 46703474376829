import {
  TO_SUBSCRIBE,
  TO_SUBSCRIBE_ERROR,
  TO_SUBSCRIBE_SUCCESS,
} from '../constants';

const INIT_STATE = {
  actualEcosytem: null,
  error: '',
};

const reducer = (state = INIT_STATE, action ) => {
  switch (action.type) {
    case TO_SUBSCRIBE:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };

    case TO_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: null,
        error: '',
      };

    case TO_SUBSCRIBE_ERROR:
      return {
        ...state,
        loading: false,
        payload: null,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default reducer;
