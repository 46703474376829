// Own
import en from './entries/en-US';
import es from './entries/es-ES';

const locales = {
  en,
  es,
}

export default locales;
