import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';

import {
  COMMENT,
  COMMENT_DELETE,
  COMMENT_LIST,
} from '../constants';

import {
  commentError,
  commentSuccess,
  getCommentListSuccess,
  getCommentListError,
  commentDeleteSuccess,
  commentDeleteError,
} from './actions';

export function* watchComment() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(COMMENT, commentSend);
}
const commentRequestAsync = async (data) => {
  const request = await apiRequest({
    api: 'CommentPost',
    props: {
      idPost: data.postId,
      content: data.commentInput,
    },
  })
    .then((response) => response)
    .catch((error) => error);
  return request;
}

const commentDeleteRequestAsync = async (data) => {
  const request = await apiRequest({
    api: 'DeleteComment',
    props: data,
  })
    .then((response) => response)
    .catch((error) => error);
  return request;
};

function* commentSend({ payload }) {
  const { data } = payload;
  const state = yield select();
  const loading = state?.comments?.loadingComments;
  if (!loading) {
    return;
  }
  try {
    const commentRequestResponse = yield call(commentRequestAsync, data);

    if (commentRequestResponse.status) {
      yield put(commentSuccess());
    } else {
      yield put(commentError(commentRequestResponse.message));
    }
  } catch (error) {
    yield put(commentError(error));
  }
}

function* commentDeleteReq({ payload }) {
  const { data } = payload;
  const state = yield select();
  const loading = state?.comments?.loadingComments;
  if (!loading) {
    return;
  }
  try {
    const commentRequestResponse = yield call(commentDeleteRequestAsync, data);
    if (commentRequestResponse.status) {
      yield put(commentDeleteSuccess());
    } else {
      yield put(commentDeleteError(commentRequestResponse.message));
    }
  } catch (error) {
    yield put(commentDeleteError(error));
  }
}

export function* watchCommentGetList() {
  yield takeEvery(COMMENT_LIST, getCommentListReq);
}

export function* watchCommentDelete() {
  yield takeEvery(COMMENT_DELETE, commentDeleteReq);
}

const commentListRequestAsync = async (data) => {
  const result = await apiRequest({
    api: 'GetComments',
    props: {
      orderBy: ['postId', 'ASC'],
      filter: {
        idPost: [data],
      },
    },
  })
    .then((response) => response)
    .catch((error) => error);
  return result;
};

function* getCommentListReq({ payload }) {
  const { data } = payload;
  const state = yield select();
  const loading = state?.comments?.loadingComments;
  if (!loading) {
    return;
  }
  try {
    const result = yield call(commentListRequestAsync, data);
    if (result.status) {
      yield put(getCommentListSuccess(result.response ?? []));
    } else {
      yield put(getCommentListError(result.Error));
    }
  } catch (error) {
    yield put(getCommentListError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchComment),
    fork(watchCommentDelete),
    fork(watchCommentGetList),
  ]);
}
