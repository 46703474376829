import {
  ROLE_LIST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_ERROR,
} from '../constants';

const INIT_STATE = {
  loading: false,
  roles: [],
  error: '',
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE_LIST:
      return {
        ...state,
        loading: true
      };

    case ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
        error: '',
      };

      case ROLE_LIST_ERROR:
        return {
          ...state,
          loading: false,
          roles: [],
          error: action.payload
      };

    default:
      return { ...state };
  }
}

export default reducer;
