import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS_ERROR,
  MARK_AS_READ_NOTIFICATIONS_SUCCESS
} from "../constants";

const INIT_STATE = {
  loading: false,
  error: '',
  notifications: null,
}

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MARK_AS_READ_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        payload: action.payload
      };

    case MARK_AS_READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload
      };

    case MARK_AS_READ_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        payload: action.payload,
        notifications: null,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        notifications: action.payload,
      };

    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        notifications: null,
      };

    default:
      return { ...state };
  }
}

export default reducer;
