import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  USER_LIST_GET_LIST,
  USER_LIST_GET_LIST_SUCCESS,
  USER_LIST_GET_LIST_ERROR,
  USER_CHANGE_ROL_PETITION_LIST,
  USER_CHANGE_ROL_PETITION_LIST_SUCCESS,
  USER_CHANGE_ROL_PETITION_LIST_ERROR,
  USER_CHANGE_ROL_PETITION_REJECT,
  USER_CHANGE_ROL_PETITION_REJECT_SUCCESS,
  USER_CHANGE_ROL_PETITION_REJECT_ERROR,
  USER_CHANGE_ROL_PETITION_APPROVAL,
  USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS,
  USER_CHANGE_ROL_PETITION_APPROVAL_ERROR
} from '../constants';

// DELETE USER
export const deleteUser = (payload) => {
  return {
    type: DELETE_USER,
    loading: true,
    payload,
  }
};
export const deleteUserSuccess = (payload) => {
  return {
    type: DELETE_USER_SUCCESS,
    loading: false,
    payload,
  }
};
export const deleteUserError = (payload) => {
  return {
    type: DELETE_USER_ERROR,
    loading: false,
    payload,
  }
};

// GET USER LIST
export const getUserList = (payload) => {
  return {
    type: USER_LIST_GET_LIST,
    payload
  }
};
export const getUserListSuccess = (payload) => {
  return {
    type: USER_LIST_GET_LIST_SUCCESS,
    payload,
  }
};
export const getUserListError = (payload) => {
  return {
    type: USER_LIST_GET_LIST_ERROR,
    payload,
  }
};

//
export const changeRolPetitionList = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_LIST,
    payload,
  }
};

export const changeRolPetitionListSuccess = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_LIST_SUCCESS,
    payload,
  }
};

export const changeRolPetitionListError = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_LIST_ERROR,
    payload,
  }
};

export const changeRolPetitionReject = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_REJECT,
    payload,
  }
};

export const changeRolPetitionRejectSuccess = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_REJECT_SUCCESS,
    payload,
  }
};

export const changeRolPetitionRejectError = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_REJECT_ERROR,
    payload,
  }
};

export const changeRolPetitionApproval = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_APPROVAL,
    payload,
  }
};

export const changeRolPetitionApprovalSuccess = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS,
    payload,
  }
};

export const changeRolPetitionApprovalError = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_APPROVAL_ERROR,
    payload,
  }
};
