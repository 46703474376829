import {
  CONTACT,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
} from '../constants';

const INIT_STATE = {
  loading: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONTACT:
      return {
        ...state,
        loading: true
      };

    case CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CONTACT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
};

export default reducer;
