import {
  FILE_MANAGER_GET_FILE_LIST,
  FILE_MANAGER_GET_FILE_LIST_SUCCESS,
  FILE_MANAGER_GET_FILE_LIST_ERROR,
  FILE_MANAGER_UPLOAD_FILE,
  FILE_MANAGER_UPLOAD_FILE_SUCCESS,
  FILE_MANAGER_UPLOAD_FILE_ERROR,
  FILE_MANAGER_DELETE_FILE,
  FILE_MANAGER_DELETE_FILE_SUCCESS,
  FILE_MANAGER_DELETE_FILE_ERROR,
  CLEAR_STATUS,
  FILE_MANAGER_LIST_WITH_ORDER,
  FILE_MANAGER_GET_LIST_SEARCH,
  GET_FILE,
  GET_FILE_ERROR,
  GET_FILE_SUCCESS
} from '../constants';

const INIT_STATE = {
  loading: false,
  finished: true,
  error: '',
  files: [],
};

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {

    case FILE_MANAGER_GET_FILE_LIST:
      return {
        ...state,
        loading: true,
      };

    case FILE_MANAGER_GET_FILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload,
      };

    case FILE_MANAGER_GET_FILE_LIST_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload
      };

    case FILE_MANAGER_UPLOAD_FILE:
      return {
        ...state,
        loading: true,
        finished: false,
      };

    case FILE_MANAGER_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        addedFile: true,
        finished: true,
      };

    case FILE_MANAGER_UPLOAD_FILE_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload,
        addedFile : false,
        finished: true,
      };

    case FILE_MANAGER_DELETE_FILE:
      return {
        ...state,
        loading: true,
        deleteFileId: action.payload,
      };

    case FILE_MANAGER_DELETE_FILE_SUCCESS:
      return {
        ...state,
        deletedFile: true,
        loading: false
      };

    case FILE_MANAGER_DELETE_FILE_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload,
        deletedFile: false
      };

    case FILE_MANAGER_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loaded: true,
          allFilesItems: state.allFilesItems,
          orderColumn: null,
        };
      }
    // eslint-disable-next-line no-case-declarations
    const sortedItems = state.allFilesItems.sort((a, b) => {
      if (a[action.payload] < b[action.payload]) return -1;
      if (a[action.payload] > b[action.payload]) return 1;
      return 0;
    });
    return {
      ...state,
      loaded: true,
      allFilesItems: sortedItems,
      orderColumn: state.orderColumns.find(
        (x) => x.column === action.payload
      ),
    };
    case FILE_MANAGER_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, filesItems: state.allFilesItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allFilesItems.filter(
        (item) =>
          item.fileUrl.toLowerCase().indexOf(keyword) > -1 ||
          item.fileName.toLowerCase().indexOf(keyword) > -1 ||
          item.ownerData.name.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loaded: true,
        filesItems: searchItems,
        searchKeyword: action.payload,
      };

    case CLEAR_STATUS:
      return { ...state, deletedFile: false, addedFile : false };

    case GET_FILE:
      return {
        ...state,
        loading: true,
        error: null,
        files: [],
        payload: action.payload
      };

    case GET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        files: action.payload,
      };

    case GET_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        files: [],
      };

    default:
      return { ...state };
  }
};

export default reducer;
