import readLocal, { localRemove, setLocal } from "./LocalStorage";

export const setToken = async (token) => {
  try {
    if (token) {
      await setLocal('sessionToken', token);
    } else {
      localRemove('sessionToken');
    }
  } catch (error) {
    console.error(error);
  }
};

export const getToken = () => {
  try {
    const token = readLocal('sessionToken');
    if (token) {
      return token;
    } else {
      localRemove('sessionToken');
    }
  } catch (error) {
    console.error(error);
  }
};
