// This here locales
module.exports = {
  // Under construction
  "under-construction.soon": "PROXIMAMENTE",
  "under-construction.text-1": "¡Prepárate para el Futuro!",
  "under-construction.text-2": `Descubre nuestras próximas mejoras y novedades. ¡No te las pierdas!`,
  // Pages
  "pages.error-title": "Vaya, parece que ha ocurrido un error!",
  "pages.error-code": "Código de error",
  "pages.go-back-home": "REGRESAR A INICIO",
  "pages.login-title-1": "INICIA SESIÓN AHORA",
  "pages.login-text-1": "Utiliza tus credenciales para iniciar sesión.",
  "pages.login-text-2": "Si aún no eres miembro, por favor,",
  "pages.login-register-here": "regístrate aquí",
  "pages.login-text-3": "Cambiar contraseña",
  "pages.login-title": "Iniciar sesión",
  "pages.name": "Nombre",
  "pages.message": "Mensaje",
  "pages.email": "Correo electrónico",
  "pages.password": "Contraseña",
  "pages.register": "Registro",
  "pages.login-button": "INICIA SESIÓN",
  "pages.forgot-password-question": "¿Olvidaste tu contraseña?",
  "pages.register-text-1":
    "Registrarse en comunidadpebur.com te permite participar fácilmente. Como residente de Burgos, puedes involucrarte en discusiones y proyectos.",
  "pages.if-you-already-have-an-account": "Si ya tienes una cuenta",
  "pages.log-in-here": "inicia sesión aquí",
  "pages.fullName": "Nombre completo",
  "pages.fullName-under-text":
    "Nombre público que aparecerá en tus publicaciones",
  "pages.fullName-under-text-2":
    "Con el objetivo de garantizar el anonimato puede ser cualquier nombre",
  "pages.password-confirm": "Confirmar Contraseña",
  "pages.forgot-title": "RECUPERAR CONTRASEÑA",
  "pages.forgot-text-1":
    "Por favor, utiliza tu correo electrónico para restablecer tu contraseña.",
  "pages.forgot-text-2": "Si no eres miembro, por favor, ",
  "pages.forgot-register": "regístrate",
  "pages.forgot-password": "Olvidé mi contraseña",
  "pages.change-password": "Cambiar contraseña",
  "pages.update-your-password": "Actualiza tu contraseña",
  "pages.update-your-password-text-1":
    "Completa los datos requeridos para unirte a comunidadpebur.com y formar parte de nuestra comunidad.",
  "pages.reset": "REINICIAR",
  "pages.burgos-take-park": "Burgos Participa",
  "pages.forging-the-future": "Forjando el Futuro",
  "pages.rural-burgos": "Rural de Burgos",
  "pages.home-text-1":
    "Un Espacio Digital para la Colaboración Innovadora del Plan Estratégico Burgos Rural 2025: Conectando Agentes, Fomentando la Innovación y Forjando un Futuro Sostenible para Nuestra Provincia",
  "pages.begin": "Empezar",
  "pages.participate-actively": "Participa Activamente",
  "pages.participate-actively-text-1":
    "Da el Primer Paso: Regístrate y Contribuye al Futuro de Burgos Participando de Forma Activa",
  "pages.start-your-journey": "INICIA TU CAMINO",
  "pages.home.register": "REGÍSTRATE",
  "pages.home.contribute-and-collaborate": "CONTRIBUYE Y COLABORA",
  "pages.home.register-text-1":
    "Registrarse en comunidadpebur.com te permite participar fácilmente. Como residente de Burgos, puedes involucrarte en discusiones y proyectos.",
  "pages.get-informed": "INFÓRMATE",
  "pages.explore-and-discover": "EXPLORA Y DESCUBRE",
  "pages.get-informed-text-1":
    "Sumérgete en comunidadpebur.com para explorar procesos colaborativos, descubrir iniciativas y filtrar información según tus intereses.",
  "pages.contribute-and-collaborate": "CONTRIBUYE Y COLABORA",
  "pages.participates": "PARTICIPA",
  "pages.actively-contributes": "Contribuye activamente",
  "pages.actively-contributes-text-1":
    "Comparte tus ideas. Participa en discusiones impulsadas por SODEBUR y colaboradores. Exprésate, comparte tus puntos de vista y colabora con la comunidad.",
  "pages.stay-informed": "MANTENTE INFORMADO",
  "pages.keep-track": "HAZ SEGUIMIENTO",
  "pages.keep-track-text-1":
    "Sigue de cerca el progreso de cada iniciativa, desde su inicio hasta su realización, y mantente informado sobre reuniones, debates y la evolución de las ideas.",
  "pages.discover-pebur-community": "Descubre Comunidad PEBUR",
  "pages.discover-pebur-community-text-1":
    "Mira nuestro video de presentación y conoce cómo podemos ayudarte a crecer",
  "pages.join-the-community": "Únete a la Comunidad",
  "pages.join-the-community-text-1":
    "¡Únete a nuestra comunidad en línea y participa activamente en la construcción del futuro de Burgos!",
  "pages.check-in": "REGISTRARSE",
  "pages.participate-without-borders": "Participa sin Fronteras",
  "pages.participate-without-borders-text-1":
    "Tu Voz Importa: Contribuye a la Diversidad y la Inclusión en Nuestra Comunidad Virtual",
  "pages.we-promote-development": "IMPULSAMOS EL DESARROLLO",
  "pages.we-promote-development-text-1":
    "Comunidad Pebur, parte del Plan Estratégico Burgos Rural (PEBUR2025) y respaldado por SODEBUR, es un espacio innovador que promueve la colaboración entre diversos actores para impulsar el desarrollo y la innovación en la provincia.",
  "pages.connection-center": "CENTRO DE CONEXIÓN",
  "pages.connection-center-text-1":
    "Comunidad Pebur promueve el diálogo y la cooperación entre los agentes del PEBUR2025, facilitando el intercambio de ideas y proyectos para el desarrollo rural en Burgos. Actúa como catalizador para la colaboración efectiva en iniciativas que moldeen el futuro de la provincia.",
  "pages.commitment-to-innovation": "COMPROMISO CON LA INNOVACIÓN",
  "pages.commitment-to-innovation-text-1":
    "Comunidad Pebur promueve una cultura de colaboración más allá de la mera participación. BurgosParticipa.com es prueba de este compromiso, proporcionando un espacio dinámico para la colaboración efectiva y la creación de oportunidades entre los agentes del desarrollo del PEBUR2025.",
  "pages.active-collaboration": "COLABORACIÓN ACTIVA",
  "pages.active-collaboration-text-1":
    "¡Únete a Comunidad Pebur y participa activamente en esta emocionante iniciativa de colaboración! Juntos, podemos construir un futuro más brillante para nuestra provincia, fundamentado en la innovación, el conocimiento compartido y la cooperación estratégica.",
  "pages.open-to-all": "ABIERTOS A TODOS",
  "pages.open-to-all-text-1":
    "Te invitamos a participar activamente, sin importar tu ubicación. Valora la diversidad de opiniones y la inclusión en nuestra plataforma comunitaria.",
  "pages.your-contribution-our-value": "TU CONTRIBUCIÓN, NUESTRO VALOR",
  "pages.your-contribution-our-value-text-1":
    "Participa en debates digitales y presenciales, enriqueciendo el diálogo con tus ideas. Comparte tus propuestas y comentarios en iniciativas existentes.",
  "pages.your-voice-matters": "TU VOZ IMPORTA",
  "pages.your-voice-matters-text-1":
    "Tu voz importa, estés donde estés. Tus perspectivas y experiencias enriquecen nuestra comunidad, impulsando una provincia de Burgos más inclusiva y representativa.",
  "pages.join-and-participate": "¡ÚNETE Y PARTICIPA!",
  "pages.join-and-participate-text-1":
    "Únete a comunidadpebur.com y contribuye al desarrollo de nuestra provincia. Tu participación es clave para mejorar el medio rural de Burgos.",
  "pages.face-to-face-meetings": "Encuentros Presenciales",
  "pages.face-to-face-meetings-text-1":
    "Conexiones Reales, Impacto Local Los encuentros presenciales en comunidadpebur.com son oportunidades vitales para que los colaboradores se reúnan y trabajen con juntamente en proyectos e iniciativas. Estos encuentros son espacios donde tu contribución y tus ideas se combinan con las de otros, creando soluciones prácticas y efectivas para nuestra provincias.",
  "pages.online-debates": "Debates Online",
  "pages.online-debates-text-1":
    "Espacios de Diálogo y Reflexión Nuestra plataforma te ofrece un espacio digital para debatir y reflexionar sobre las diversas iniciativas y proyectos. Aquí, puedes aportar tus opiniones, conocer distintas perspectivas y contribuir al desarrollo colectivo de ideas. Los debates online son fundamental es para mantener una comunicación activa y efectiva entre los participantes.",
  "pages.proposals": "Propuestas",
  "pages.proposals-text-1": `Impulsando Ideas hacia la Acción Las propuestas en comunidadpebur.com son el núcleo de la innovación y el cambio. Son tus ideas transformadas en planes de acción, esperando colaboración y apoyo de la comunidad para convertirse en realidades tangibles. Cada propuesta que apoyas contribuye a la evolución y mejora de nuestra provincia

    <h2 style='margin-top: 40px;'>Comentarios</h2>

    Reflexiones que Enriquecen
    Tus comentarios en comunidadpebur.com son esenciales para enriquecer las discusiones y aportar valor a las propuestas y debates. Cada opinión, sugerencia o crítica es una pieza clave en el mosaico de nuestra colaboración, ayudando a mantener un diálogo constructivo y orientado hacia la mejora del medio rural de la provincia de Burgos.`,
  "pages.active-participation": "Participación Activa",
  "pages.active-participation-text-1": `<p>Tu Rol en Nuestra Comunidad <br>
    En comunidadpebur.com  cada elemento de colaboración es una invitación a participar activamente en el desarrollo y mejora de nuestra provincia. Tu participación, ya sea en encuentros presenciales, debates online, proponiendo ideas o a través de comentarios, es crucial para construir una comunidad más fuerte y unida. ¡Te invitamos a ser parte activa de este emocionante proceso colaborativo!
    </p>`,
  "pages.help": "Ayuda",
  "pages.sign-out": "Cerrar sesión",
  "pages.home": "Inicio",
  "pages.my-account": "Mi cuenta",
  "pages.repopulation-and-quality-of-life": "Repoblación y calidad de vida",
  "pages.business-development": "Desarrollo empresarial",
  "pages.green-transition": "Transición Verde",
  "pages.tourism-development": "Desarrollo Turístico",
  "pages.smart-territory": "Territorio Inteligente",
  "pages.profile": "Perfil",
  "pages.information": "Información",
  "pages.preferences": "Preferencias",
  "pages.notifications": "Notificaciones",
  "pages.user-admin": "Administrador de usuarios",
  "pages.faq": "FAQ's",
  "pages.collaborative-process": "Procesos Colaborativos",
  "pages.publications": "Publicaciones",
  "pages.files": "Archivos",
  "pages.energy-and-environment": "Energía y Medio Ambiente",
  "pages.bio-economy": "Bioeconomía",
  "pages.smart-territory-text-1":
    "Sé parte del cambio hacia un territorio inteligente y una gobernanza colaborativa",
  "pages.smart-territory-text-2":
    "Promovemos un desarrollo rural inteligente y una gobernanza inclusiva en Burgos, integrando a todos los actores en la transición digital de administraciones públicas y actividades económicas y sociales.",
  "pages.smart-territory-text-3":
    "Regístrate en ComunidadPebur.com y contribuye a la transformación digital del medio rural de Burgos.",
  "pages.sign-up-to-get-started": "REGISTRATE PARA EMPEZAR",
  "pages.participate-now": "PARTICIPA AHORA",
  "pages.join-comunidadpebur-com": "ÚNETE A COMUNIDADPEBUR.COM",
  "pages.benefits": "Beneficios",
  "pages.benefits-text-1": `• Nuevos Sectores: Economía circular, economía
    plateada, turismo sostenible. <br />
    • Emprendimiento: Apoyo a micro pymes y pymes
    para mejorar su competitividad. <br />
    • Talento: Atracción, captación y retención de talento
    en el medio rural. <br />`,
  "pages.benefits-text-2":
    "Únete a ComunidadPebur.com y descubre oportunidades de negocio y empleo.",
  "pages.discover-the-charm-of-rural-burgos":
    "Descubre el Encanto del Medio Rural de Burgos",
  "pages.discover-the-charm-of-rural-burgos-text-1":
    "Únete a ComunidadPebur.com y participa en el desarrollo turístico sostenible.",
  "pages.revaluation-of-your": "Revalorización de su",
  "pages.diverse-heritage": "Patrimonio Diverso",
  "pages.diverse-heritage-text-1": `Promovemos la consolidación del medio rural de Burgos como un destino turístico sostenible,
    revalorizando sus recursos patrimoniales diversos: monumentos, naturaleza, patrimonio inmaterial,
    etnográfico y enogastronómico.`,
  "pages.touristic-development-text-1":
    "Forma parte del impulso al turismo sostenible y la revalorización patrimonial",
  "pages.touristic-development-text-2":
    "Regístrate en ComunidadPebur.com y contribuye al desarrollo turístico del medio rural de Burgos.",
  "pages.green-transition-text-1": `• Energía Sostenible: Promoción de fuentes de energía
    renovable.<br />
    • Medio Ambiente: Protección y conservación del entorno
    natural.<br />
    • Bioeconomía: Desarrollo de actividades económicas
    basadas en recursos biológicos.`,
  "pages.green-transition-text-2":
    "Potenciando un Modelo Productivo Sostenible en Burgos",
  "pages.green-transition-text-3":
    "Únete a ComunidadPebur.com y participa en la transición verde.",
  "pages.green-transition-text-4": "Potenciando un Modelo",
  "pages.green-transition-text-5": "Productivo Sostenible en Burgos",
  "pages.green-transition-text-6": `La transición verde dinamiza el potencial del medio rural para implementar un modelo productivo
    respetuoso con el medio natural, climáticamente neutro y circular. Aprovechamos responsablemente
    los recursos endógenos de la provincia de Burgos.`,
  "pages.green-transition-text-7":
    "Forma parte del cambio hacia un futuro sostenible en tu comunidad",
  "pages.green-transition-text-8":
    "Regístrate en ComunidadPebur.com y contribuye a la transición verde en el medio rural.",
  "pages.boost-your-business-future-in-rural-areas":
    "Impulsa tu Futuro Empresarial en el Medio Rural",
  "pages.economic-boost-and-employment": "Impulso Económico y Empleo",
  "pages.innovative-in-the-rural-environment": "Innovador en el Medio Rural",
  "pages.innovative-in-the-rural-environment-text-1":
    "El desarrollo empresarial y la generación de empleo son fundamentales para la pervivencia del medio rural como espacio de oportunidad. Impulsamos nuevos sectores económicos (economía circular, economía plateada, turismo sostenible) para crear empleo innovador y de calidad.",
  "pages.be-part-of-the-positive-change-in-your-community":
    "Sé parte del cambio positivo en tu comunidad",
  "pages.be-part-of-the-positive-change-in-your-community-text-1":
    "Regístrate en ComunidadPebur.com y contribuye al desarrollo empresarial y a la generación de empleo en el medio rural.",
  "pages.transform-your-life": "Transforma tu Vida",
  "pages.in-the-rural-environment": "en el Medio Rural",
  "pages.in-the-rural-environment-text-1":
    "Únete a ComunidadPebur.com y descubre las oportunidades en tu territorio.",
  "pages.keys-to-rural-repopulation": "Claves para la Repoblación Rural",
  "pages.keys-to-rural-repopulation-text-1": `La repoblación rural es clave para promover el territorio como un espacio donde las personas
    pueden desarrollar plenamente su proyecto vital. Mejorar la calidad de vida pasa por reducir las
    desigualdades en servicios básicos como educación, sanidad, transporte y conectividad.`,
  "pages.register-at-comunidad-pebur-com-and-help-improve-life-in-rural-areas":
    "Regístrate en ComunidadPebur.com y contribuye a mejorar la vida en el medio rural.",
  "pages.rural-repopulation-text-1": `
    • Servicios básicos: mejora de la educación, la salud, el transporte y la conectividad.<br/>
    • Oportunidades: facilidades para emprender y vivir en un entorno saludable.`,
  "pages.need-help": "¿Necesitas Ayuda?",
  "pages.need-help-text-1": `<strong>¿Te sientes algo desorientado en comunidadpebur.com?</strong><br />
    No te preocupes, estamos aquí para guiarte. Si tienes dudas sobre cualquier aspecto de la plataforma, desde los procesos participativos hasta los detalles de tu cuenta, estamos listos para asistirte. Si tienes preguntas adicionales o necesitas asistencia con algún aspecto de tu participación en comunidadpebur.com, no dudes en contactarnos: Para consultas generales, escríbenos a info@comunidadpebur.com.
    Para soporte técnico o problemas durante la participación, contáctanos en info@comunidadpebur.com con el asunto “Soporte”. Recuerda, tu participación es vital para nosotros`,
  "pages.write-to-us-to-resolve-your-doubts":
    "Escríbenos para Resolver tus Dudas",
  "pages.master-the-platform-with-confidence":
    "¡Domina la Plataforma con Confianza!",
  "pages.find-answers-to-all-your-questions":
    "Encuentra Respuestas a Todas tus Preguntas",
  "pages.text-1": `Descarga la guía definitiva para utilizar comunidadpebur.com como un verdadero experto! Estos PDF contiene todas las respuestas a tus preguntas frecuentes,
    ayudándote a aprovechar al máximo nuestra plataforma. Si aún te quedan dudas después de revisarlo, ¡no dudes en pedir ayuda haciendo <a href='/help'>clic aquí!`,
  "pages.title-1": "GUÍA DEL MODERADOR ONLINE",
  "pages.title-2": "RESUMEN EJECUTIVO PEBUR 2025",
  "pages.title-3": "FAQS EN PDF",
  "pages.see": "Ver",
  "pages.looking-for-other-help": "¿Buscas otro tipo de ayuda?",
  "pages.title-4": `<strong>¿Estás un poco perdido en comunidadpebur.com?</strong> <br />
    No te angusties, estamos aquí para ayudarte. Si tienes preguntas sobre
    cualquier aspecto de la plataforma, desde los procesos de participación
    hasta los detalles de tu cuenta, estamos preparados para asistirte.`,
  "pages.contact": "Contactar",
  "pages.user-type": "Tipo de Usuario",
  "pages.user-data": "Datos del usuario",
  "pages.full-name": "Nombre completo",
  "pages.choose-the-desired-role": "Elige el rol deseado",
  "pages.full-name-under-text":
    "Nombre público que aparecerá en tus publicaciones",
  "pages.full-name-under-text-2":
    "Para garantizar el anonimato, puede ser cualquier nombre.",
  "pages.request-change": "Solicitud de cambio de rol",
  "pages.save": "Guardar",
  "pages.role-change-request-text-3":
    "Para ver los permisos de los diferentes tipos de usuarios haz clic ",
  "pages.role-change-request-text-4": "aquí.",
  "pages.select-your-ecosystem-s": "Selecciona tu/s Ecosistema/s",
  "pages.ecosystems": "Ecosistemas",

  // Do not change this translations
  "pages.repoblacion-y-calidad": "Repoblación y calidad de vida",
  "pages.desarrollo-empresarial": "Desarrollo empresarial y del empleo",
  "pages.transision-verde-sostenible":
    "Transición verde: energía y medio ambiente",
  "pages.transision-verde-bioecomia": "Transición verde: bioeconomía",
  "pages.repoblacion-y-calidad-de-vida": "Repoblación y calidad de vida",
  "pages.desarrollo-turisitco": "Desarrollo turístico y patrimonial",
  "pages.territorio-inteligente": "Territorio inteligente y gobernanza",
  "pages.desarrollo-empresarial-y-empleo":
    "Desarrollo empresarial y del empleo",
  "pages.transicion-verde": "Transición Verde",
  "pages.desarrollo-turistico": "Desarrollo Turístico",

  "pages.users": "Usuarios",
  "pages.faqs": "FAQ's",
  "pages.add-new": "Agregar Nuevo",
  "pages.orderby": "Ordenar por : ",
  "pages.total": "Total: ",
  "pages.viewing": "Viendo : ",
  "pages.1": "1",
  "pages.create": "Crear",
  "pages.detail": "Detalle",
  "pages.debate": "Debate",
  "pages.return": "Retorno",
  "pages.follow-up": "Seguimiento",
  "pages.actions": "Acciones",
  "pages.phase-1": "Fase 1",
  "pages.phase-2": "Fase 2",
  "pages.phase-3": "Fase 3",
  "pages.phase-4": "Fase 4",
  "pages.date-begin": "Fecha de inicio",
  "pages.date-ending": "Fecha de fin",
  "pages.comments": "Comentarios",
  "pages.send": "Enviar",
  "pages.add-comment": "Añadir un comentario",
  "pages.action-line": "Linea de actuación",
  "pages.delete": "Eliminar",
  "pages.change-phase": "Cambiar fase",
  "pages.greeting": "Espacio de publicaciones",
  "pages.subtitle":
    "Explora, Comparte y Participa: Únete a una Comunidad de Creadores y Comparte tu Voz",
  "pages.subtitle-1":
    "Diversidad de Contenidos: Explora Más Detalles en Cada Publicación",
  "pages.more-information": "Más información",
  "pages.posts": "POSTEOS",
  "pages.posts-text": `En esta sección, los miembros de la comunidad de PEBUR pueden compartir sus aportes, comentarios y reflexiones sobre la provincia. Desde sugerencias para mejorar la infraestructura hasta historias personales sobre la vida en la región, aquí es donde puedes hacer que tu voz se escuche y contribuir al desarrollo y bienestar de nuestra provincia.`,
  "pages.texts-videos": "FOTOS Y VIDEOS",
  "pages.texts-text": `Explora nuestra provincia a través de las imágenes y videos capturados por sus residentes. Desde paisajes impresionantes hasta eventos culturales, esta sección es un escaparate para la belleza y la diversidad de nuestra región`,
  "pages.events": "EVENTOS",
  "pages.events-text": `Descubre los eventos más destacados que tienen lugar en nuestra provincia. Desde festivales y ferias hasta actividades deportivas y culturales, aquí encontrarás información sobre cómo participar y enriquecer tu experiencia en nuestra comunidad.`,
  "pages.surveys": "ENCUESTAS",
  "pages.surveys-text": `Tu opinión es fundamental para el desarrollo y crecimiento de nuestra provincia. Participa en nuestras encuestas para compartir tus ideas, preocupaciones y visiones para el futuro de nuestra comunidad. ¡Únete a nosotros en el camino hacia una provincia mejor para todos!`,
  "pages.approval": "APROBACIÓN",
  "pages.approval-text": `Todas las publicaciones serán revisadas y aprobadas por un administrador de PEBUR. Recibirá una notificación una vez que su publicación haya sido aprobada.`,
  "pages.post": "Post",
  "pages.video": "Video",
  "pages.photos-and-videos": "Fotos y Video",
  "pages.meet": "Reunión",
  "pages.vote": "Voto",
  "pages.publish": "Publicar",
  "pages.title": `Título`,
  "pages.description": `Descripción`,
  "pages.meeting-type": `Tipo de reunión`,
  "pages.address": `Dirección`,
  "pages.main-image": `Imagen principal`,
  "pages.link": `URL`,
  "pages.date": `Fecha`,
  "pages.time": `Hora`,
  "pages.write-a-description": `Escribe una descripción`,
  "pages.question": `Pregunta`,
  "pages.options": `Opciones`,
  "pages.allowMultipleResponses": `Permitir múltiples respuestas`,
  "pages.voting": `Votación`,
  "pages.not-published": `No publicado`,
  "pages.add-an-option": `Agregar una opción`,
  "pages.read-more": `Leer más`,
  "pages.read-less": `Leer menos`,
  "pages.you-are-seeing-all-the-posts": `Estás viendo todas las publicaciones`,
  "pages.process-information": `Información del proceso`,
  "pages.meeting-main-image": "Imagen principal de la reunión",
  "pages.phase-details": "Detalles de la fase",
  "pages.process-name": `Nombre del proceso`,
  "pages.begin-date": `Fecha de inicio`,
  "pages.ending-date": `Fecha de finalización`,
  "pages.previous": `Previo`,
  "pages.next": `Siguiente`,
  "pages.process-debate": `Debate sobre el proceso`,
  "pages.process-return": `Proceso de retorno`,
  "pages.process-monitoring": `Monitoreo de procesos`,
  "pages.search": `Buscar`,
  "pages.votes": `Votos`,
  "pages.published": `Publicado`,
  "pages.development-by-nattuar": `Desarrollo por NattuAR`,
  "pages.nattuar": `NattuAR`,
  "pages.development-by": `Desarrollo por`,
  "pages.upload-file": `Subir archivo`,
  "pages.cancel": `Cancelar`,
  "pages.submit": `Entregar`,
  "pages.display-options": `Opciones de visualización`,
  "pages.approve": `Aprobar`,
  "pages.reject": `Rechazar`,
  "pages.filters": `Filtros`,
  "pages.user-guide": "Guía del usuario",
  "pages.the-platform": "La plataforma",
  "pages.the-platform-text-1": `Aprende paso a paso cómo registrarte, configurar tu perfil, participar en procesos colaborativos, administrar archivos, crear publicaciones y mucho más.`,

  "pages.how-to-register": "Cómo registrarse",
  "pages.how-to-register-text-1": `<p>
    <strong>Accede a la plataforma:</strong>
    Ingresa a la plataforma y haz clic en el botón "Empezar".
    </p>
    </p>
      <strong>Registro de cuenta:</strong>
      Si aún no tienes una cuenta,
      regístrate de manera sencilla. Haz clic en "Regístrate"
      y completa los campos solicitados: nombre completo,
      correo electrónico y contraseña.
    </p>
    </p>
      <strong>Inicio como ciudadano:</strong>
      Inicialmente, tu registro será
      como ciudadano, pero podrás cambiar tu rol desde tu
      perfil más adelante
    </p>`,
  "pages.profile-settings": "Configuración de perfil",
  "pages.profile-settings-text-1": `<p>
    <strong>Accede a tu perfil:</strong>
      Una vez iniciada la sesión, dirígete a "Mi Cuenta" y selecciona "Perfil".
    </p>
    <p>
      <strong>Foto de perfil:</strong>
      Cambia tu foto de perfil eligiendo una que te guste.
      Revisión y modificación de datos: Verifica que tus datos sean correctos y, de no
      ser así, modifícalos.
    </p>
    <p>
      <strong>Cambio de rol:</strong>
      En esta sección, podrás elegir el tipo de usuario que deseas
      ser. Comenzarás como Ciudadano, pero puedes solicitar un ascenso a Agente
      social o económico, Agente PEBUR, o Administrador general. Después de
      enviar la solicitud, espera la aprobación, la cual será notificada por correo
      electrónico.
    </p>
    <p>
      <strong>Consulta de dudas:</strong>
      Si tienes dudas sobre los tipos de usuarios, consulta la sección de FAQs.
    </p>
    <p>
      <strong>Selección de ecosistemas:</strong>
      Elige los ecosistemas en los que deseas participar.
    </p>
    `,
  "pages.collaborative-processes": "Procesos Colaborativos",
  "pages.collaborative-processes-text-1": `<p>
    <strong>Acceso a los procesos: </strong>
    En el menú lateral, selecciona un ecosistema y luego
    dirígete a "Procesos Colaborativos".
    </p>
    <p>
      <strong>Selección del proceso: </strong>
      Elige el proceso que te interese. Cada proceso tiene
      cuatro fases: Información, Debate, Retorno y Seguimiento.
    </p>
    <p>
      <strong>Detalles de la fase:</strong>
      En el lado derecho, podrás ver la fase actual del proceso y
      las fechas de inicio y fin.
    </p>
    <p>
      <strong>Creación de un nuevo proceso:</strong>
      Para crear un nuevo proceso colaborativo, haz
      clic en "Agregar Nuevo".
      Selecciona una imagen para el proceso.
      Detalla la primera fase, incluye el nombre del proceso y su línea de actuación.
      Define las fechas de inicio y fin del proceso.
      Proporciona la información necesaria y haz clic en "Siguiente".
      Completa los detalles de las fases siguientes y haz clic en "Publicar".
    </p>
    <p>
      <strong>Publicación y gestión:</strong>
      Una vez publicado, podrás ver tu proceso y avanzar de
      fase haciendo clic en "Acciones" y luego en "Cambiar Fase". También podrás
      eliminar el proceso desde esa sección o haciendo clic derecho en la lista de
      procesos y seleccionando "Eliminar".
    </p>`,
  "pages.files-text-1": `<p>
    <strong>Acceso a archivos:</strong>
      En el menú lateral, selecciona un ecosistema y
      luego dirígete a "Archivos".
    </p>
    <p>
      <strong>Visualización y descarga: </strong>
      Aquí podrás ver todos los archivos subidos
      en el ecosistema. Para visualizar y descargar, haz clic en "Descargar".
      Subir nuevos archivos: Para subir tus propios archivos, haz clic en
      "Agregar Nuevo". Puedes arrastrar los archivos o seleccionarlos manualmente.
      Selecciona el archivo deseado (imagen o documento) y haz clic en
      "Enviar".
      Espera a que el archivo se cargue.
    </p>
    <p>
      <strong>Eliminación de archivos: </strong>
      Si subiste un archivo incorrecto, haz clic derecho sobre el archivo y selecciona la opción "Eliminar".
    </p>`,
  "pages.publications-text-1": `<p>
    <strong>Acceso a publicaciones:</strong>
      En el menú lateral, selecciona un ecosistema y luego
      dirígete a "Publicaciones".
    </p>
    <p>
      <strong>Participación en publicaciones:</strong>
      Aquí podrás interactuar con las publicaciones del ecosistema, dejando comentarios, dando likes y manteniéndote informado.
    </p>
    <p>
      <strong>Creación de publicaciones:</strong>
      Puedes crear tus propias publicaciones con información para iniciar debates.
    </p>
    <p>
      <strong>Fotos y videos:</strong>
      Puedes crear tus propias publicaciones de imágenes o videos para iniciar debates.
    </p>
    <p>
      <strong>Agendar reuniones: </strong>
      Puedes agendar reuniones, ya sean presenciales u online.
      Si es online, deja el enlace de la reunión; si es presencial, proporciona la dirección. No olvides incluir la fecha y hora.
      Creación de votaciones: Crea votaciones formulando preguntas o comentando
      ideas. Proporciona respuestas fijas para que la gente vote y permite opciones
      múltiples si es necesario.
  </p>`,
  "pages.done": "Hecho",
  "pages.change-image": "Cambiar imagen",
  "pages.has-called-a-meeting-with-the-following-details":
    "ha convocado a una reunión con los siguientes detalles:",
  "pages.alert-delete": "Eliminar",
  "pages.alert-delete-text-1": "¿Esta seguro de eliminar la publicación?",
  "pages.alert-delete-text-2": "¿Esta seguro de eliminar el comentario?",
  "pages.thanks": "¡Gracias!",
  "pages.saved": "Guardado",
  "pages.like": "Me gusta",
  "pages.likes": "Me gustas",
  "pages.upload-by": "Subido por",
  "pages.name-of-file": "Nombre del archivo",
  "pages.download": "Descargar",
  "pages.permissions": "Permisos",
  "pages.pebur-community": "Comunidad Pebur",
  "pages.phone-number-1": "947 061929",
  "pages.email-1": "info@comunidadpebur.com",
  "pages.address-1": "Avenida de la Paz, 34. Entreplanta. 09004 Burgos",
  "pages.executive-summary-2025": "Resumen ejecutivo 2025",
  "pages.moderator-s-guide": "Moderator's Guide",
  "pages.all-right-reserved": "Todos los derechos reservados.",
  "pages.links": "Enlaces",
  "pages.footer.contact": "Contacto",
  "pages.funded-by": "Financiado por",
  "pages.reject-reason": "Motivo del rechazo",
  "pages.write-reason": "Escribe la razón",
  "pages.change-rol-nothing": "Sin solicitudes",
  "pages.delete-user-question-text":
    "¿Está seguro de que quiere eliminar este usuario?",
  "pages.role-change-request-text-1":
    "El Administrador de comunidadpebur.com ha recibido su solicitud y contactará con usted lo antes posible para darle acceso",
  "pages.role-change-request-text-2": "Solicitud pendiente",
  "pages.role-change-request": "Solicitud de cambio de rol",
  "pages.status": "Estado",
  "pages.author": "Autor",
  "pages.type": "Tipo",
  "pages.password-recovery-request-sent":
    "Solicitud de recuperación de contraseña enviada",
  "pages.password-recovery-request-sent-text-1":
    "Por favor, revisa tu correo electrónico y sigue las instrucciones para restablecer el acceso a tu cuenta.",
  "pages.updated-user-data": "Datos de usuario actualizados",
  "pages.updated-user-data-text-1":
    "Tus datos personales han sido modificados con éxito.",
  "pages.your-message-has-been-sent": "Su mensaje ha sido enviado",
  "pages.your-message-has-been-sent-text-1":
    "Gracias por contactarnos. Nos pondremos en contacto con usted lo antes posible.",
  "pages.business-and-employment-development":
    "Desarrollo empresarial y de empleo",
  "pages.error": "Error",
  "pages.drag-your-files-here-to-upload-them":
    "Arrastra tus archivos aquí para subirlos",
  "pages.phase": "Fase",
  "pages.email-already-registered": "Correo Electrónico ya Registrado",
  "pages.email-already-registered-text-1":
    'El correo electrónico que has ingresado ya está asociado a una cuenta en PEBU2025. Si olvidaste tu contraseña, puedes restablecerla a través de la opción "¿Olvidaste tu contraseña?" en la página de inicio de sesión.',
  "pages.edit": "Editar",
  "pages.attachments": "Archivos adjuntos",
  "pages.add-new-folder": "Añadir nueva carpeta",

  /* Begin Post types */
  "postTypes.type-2": "Post",
  "postTypes.type-5": "Video y Fotos",
  "postTypes.type-4": "Reunión",
  "postTypes.type-3": "Votación",
  /* Ending Post types */

  // Profile
  "profile.citizen": "Ciudadano",
  "profile.associate-agents": "Agentes asociados",
  "profile.collaborator-administrator": "Administrador colaborador",
  "profile.mediator-administrator": "Administrador mediador",
  "profile.general-administrator": "Administrador general",
  "profile.agent-pebur2025": "Agente PEBUR2025",
  "profile.provincial-social-economic-agent":
    "Agente social/económico provincia",
  "profile.provincialSocialEconomicAgent": "Agente social/económico provincia",
  "profile.agentPebur2025": "Agente PEBUR2025",
  "profile.administradorGeneral": "Administrador General",

  // Error
  "error.page-not-exists":
    "Oops!  Parece que has llegado a una página que no existe.",
  "error.invalid-url": "URL inválida",
  "error.incorrect-username-or-password":
    "Usuario o contraseña incorrectos. Por favor, verifica tus datos e inténtalo de nuevo.",
  "pages.close": "Cerrar",
  "pages.view-profile": "Ver perfil",
  "pages.reason-for-request": "Motivo de la solicitud",
  "pages.repopulation": "Repoblación",
  "pages.see-all": "Ver todo",
  "pages.green-transition-energy-and-environment":
    "Transición verde: energía y medio ambiente",
};
