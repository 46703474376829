import {
  FILE_MANAGER_GET_FILE_LIST,
  FILE_MANAGER_GET_FILE_LIST_SUCCESS,
  FILE_MANAGER_GET_FILE_LIST_ERROR,
  FILE_MANAGER_UPLOAD_FILE,
  FILE_MANAGER_UPLOAD_FILE_SUCCESS,
  FILE_MANAGER_UPLOAD_FILE_ERROR,
  FILE_MANAGER_DELETE_FILE,
  FILE_MANAGER_DELETE_FILE_SUCCESS,
  FILE_MANAGER_DELETE_FILE_ERROR,
  FILE_MANAGER_LIST_WITH_ORDER,
  FILE_MANAGER_GET_LIST_SEARCH,
  CLEAR_STATUS,
  GET_FILE,
  GET_FILE_ERROR,
  GET_FILE_SUCCESS,
  ATTACHMENTS_LIST,
  ATTACHMENTS_LIST_SUCCESS,
  ATTACHMENTS_LIST_ERROR,
  SAVE_ATTACHMENTS,
  SAVE_ATTACHMENTS_SUCCESS,
  SAVE_ATTACHMENTS_ERROR,
  DELETE_ATTACHMENTS,
  DELETE_ATTACHMENTS_SUCCESS,
  DELETE_ATTACHMENTS_ERROR,
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_ERROR
} from '../constants';

export const getFileList = (payload) => {
  return {
    type: FILE_MANAGER_GET_FILE_LIST,
    payload
  }
};

export const getFileListSuccess = (payload) => {
  return {
    type: FILE_MANAGER_GET_FILE_LIST_SUCCESS,
    payload,
  }
};

export const getFileListError = (payload) => {
  return {
    type: FILE_MANAGER_GET_FILE_LIST_ERROR,
    payload,
  }
};

export const uploadFile = (payload) => {
  return {
    type: FILE_MANAGER_UPLOAD_FILE,
    payload
  }
};

export const uploadFileSuccess = (payload) => {
  return {
    type: FILE_MANAGER_UPLOAD_FILE_SUCCESS,
    payload
  }
};

export const uploadFileError = (payload) => {
  return {
    type: FILE_MANAGER_UPLOAD_FILE_ERROR,
    payload,
  }
};

// TODO: To after
export const deleteFile = (fileId, idEco ) => ({
  type: FILE_MANAGER_DELETE_FILE,
  payload: { fileId, idEco }
});

export const deleteFileSuccess = () => ({
  type: FILE_MANAGER_DELETE_FILE_SUCCESS,
});

export const deleteFileError = (error) => ({
  type: FILE_MANAGER_DELETE_FILE_ERROR,
  payload: error,
});

export const clearStatus = () => {
  return {
    type: CLEAR_STATUS
  }
};

export const getFileManagerListWithOrder = (column) => ({
  type: FILE_MANAGER_LIST_WITH_ORDER,
  payload: column,
});

export const getFileManagerListSearch = (keyword) => ({
  type: FILE_MANAGER_GET_LIST_SEARCH,
  payload: keyword,
});

export const getFile = (payload) => {
  return {
    type: GET_FILE,
    payload
  }
};

export const getFileSuccess = (payload) => {
  return {
    type: GET_FILE_SUCCESS,
    payload,
  }
};

export const getFileError = (payload) => {
  return {
    type: GET_FILE_ERROR,
    payload,
  }
};

export const getAttachmentsList = (payload) => {
  return {
    type: ATTACHMENTS_LIST,
    payload
  }
};

export const getAttachmentsListSuccess = (payload) => {
  return {
    type: ATTACHMENTS_LIST_SUCCESS,
    payload,
  }
};

export const getAttachmentsListError = (payload) => {
  return {
    type: ATTACHMENTS_LIST_ERROR,
    payload,
  }
};


export const saveAttachment = (payload) => {
  return {
    type: SAVE_ATTACHMENTS,
    payload
  }
};

export const saveAttachmentSuccess = (payload) => {
  return {
    type: SAVE_ATTACHMENTS_SUCCESS,
    payload
  }
};

export const saveAttachmentError = (payload) => {
  return {
    type: SAVE_ATTACHMENTS_ERROR,
    payload,
  }
};

export const deleteAttachments = (payload) => {
  return {
    type: DELETE_ATTACHMENTS,
    payload
  }
};

export const deleteAttachmentsSuccess = () => {
  return {
    type: DELETE_ATTACHMENTS_SUCCESS,
  }
};

export const deleteAttachmentsError = (payload) => {
  return {
    type: DELETE_ATTACHMENTS_ERROR,
    payload,
  }
};

export const createFolder = (payload) => {
  return {
    type: CREATE_FOLDER,
    payload
  }
};

export const createFolderSuccess = () => {
  return {
    type: CREATE_FOLDER_SUCCESS,
  }
};

export const createFolderError = (payload) => {
  return {
    type: CREATE_FOLDER_ERROR,
    payload,
  }
};
