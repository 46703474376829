import {
  TO_SUBSCRIBE,
  TO_SUBSCRIBE_SUCCESS,
  TO_SUBSCRIBE_ERROR,
} from '../constants';

export const toSubscribe = (payload) => {
  return {
    type: TO_SUBSCRIBE,
    loading: true,
    payload,
  }
};

export const toSubscribeSuccess = () => {
  return {
    type: TO_SUBSCRIBE_SUCCESS,
    loading: false,
    payload: null,
  }
};

export const toSubscribeError = (payload) => {
  return {
    type: TO_SUBSCRIBE_ERROR,
    loading: false,
    payload,
  }
};
