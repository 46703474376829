import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_SOCIAL,
  LOGIN_USER_SOCIAL_SUCCESS,
  LOGIN_USER_SOCIAL_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  LOAD_USER_INFO,
  LOAD_USER_INFO_SUCCESS,
  LOAD_USER_INFO_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  CLEAR_STATUS,
} from '../constants';

// BASIC LOGIN
export const basicLogin = (user, navigate) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  }
};
export const basicLoginSuccess = (user) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  }
};
export const basicLoginError = (message) => {
  return {
    type: LOGIN_USER_ERROR,
    payload: { message },
  }
};

// LOGIN WITH SOCIAL
export const loginWithSocial = (payload) => {
  return {
    type: LOGIN_USER_SOCIAL,
    payload,
  }
};
export const loginWithSocialSuccess = (payload) => {
  return {
    type: LOGIN_USER_SOCIAL_SUCCESS,
    payload,
  }
};
export const loginWithSocialError = (payload) => {
  return {
    type: LOGIN_USER_SOCIAL_ERROR,
    payload,
  }
};

// REGISTER
export const register = (payload) => {
  return {
    type: REGISTER_USER,
    payload,
  }
};
export const registerSuccess = (payload) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload,
  }
};
export const registerError = (payload) => {
  return {
    type: REGISTER_USER_ERROR,
    payload,
  }
};

// FORGOT PASSWORD
export const forgotPassword = (forgotUserMail, navigate) => {
  return {
    type: FORGOT_PASSWORD,
    payload: { forgotUserMail, navigate },
  }
};
export const forgotPasswordSuccess = (forgotUserMail) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail,
  }
};
export const forgotPasswordError = (message) => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: { message },
  }
};

// AUTH UPDATE PASSWORD
export const updatePassword = (data) => {
  return {
    type: UPDATE_PASSWORD,
    loading: true,
    payload: data,
  }
};
export const updatePasswordSuccess = () => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    loading: false,
    payload: null,
    error: null,
  }
};
export const updatePasswordError = (error) => {
  return {
    type: UPDATE_PASSWORD_ERROR,
    payload: null,
    loading: false,
    error,
  }
};

// AUTH GET USER INFORMATION
export const loadUserInfo = () => {
  return {
    type: LOAD_USER_INFO,
  }
};
export const loadUserInfoSuccess = (user) => ({
  type: LOAD_USER_INFO_SUCCESS,
  payload: user,
});
export const loadUserInfoError = (message) => {
  return {
    type: LOAD_USER_INFO_ERROR,
    payload: { message },
  }
};

// AUTH LOGOUT
export const logout = () => {
  return {
    type: LOGOUT,
  }
};
export const logoutSuccess = (payload) => {
  return {
    type: LOGOUT_SUCCESS,
    payload,
  }
};
export const logoutError = (payload) => {
  return {
    type: LOGOUT_ERROR,
    payload,
  }
};

export const clear = () => {
  return {
    type: CLEAR_STATUS
  }
};
