import {
  USER_UPLOAD_PROFILE_PICTURE,
  USER_UPLOAD_PROFILE_PICTURE_SUCCESS,
  USER_UPLOAD_PROFILE_PICTURE_ERROR,
  USER_UPDATE_PROFILE_DATA,
  USER_UPDATE_PROFILE_DATA_SUCCESS,
  USER_UPDATE_PROFILE_DATA_ERROR,
  USER_CHANGE_ROL_PETITION,
  USER_CHANGE_ROL_PETITION_SUCCESS,
  USER_CHANGE_ROL_PETITION_ERROR,
  USER_CHANGE_ROL_PETITION_LIST,
  USER_CHANGE_ROL_PETITION_LIST_SUCCESS,
  USER_CHANGE_ROL_PETITION_LIST_ERROR,
} from '../constants';

export const uploadProfilePicture = (payload) => {
  return {
    type: USER_UPLOAD_PROFILE_PICTURE,
    payload
  }
};

export const uploadProfilePictureSuccess = (payload) => {
  return {
    type: USER_UPLOAD_PROFILE_PICTURE_SUCCESS,
    payload,
  }
};

export const uploadProfilePictureError = (payload) => {
  return {
    type: USER_UPLOAD_PROFILE_PICTURE_ERROR,
    payload,
  }
};

export const updateProfileData = (payload) => {
  return {
    type: USER_UPDATE_PROFILE_DATA,
    payload,
  }
};

export const updateProfileDataSuccess = (payload) => {
  return {
    type: USER_UPDATE_PROFILE_DATA_SUCCESS,
    payload,
  }
};

export const updateProfileDataError = (payload) => ({
  type: USER_UPDATE_PROFILE_DATA_ERROR,
  payload,
});

export const changeRolPetition = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION,
    payload,
  }
};

export const changeRolPetitionSuccess = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_SUCCESS,
    payload,
  }
};

export const changeRolPetitionError = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_ERROR,
    payload,
  }
};

export const changeRolPetitionList = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_LIST,
    payload,
  }
};

export const changeRolPetitionListSuccess = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_LIST_SUCCESS,
    payload,
  }
};

export const changeRolPetitionListError = (payload) => {
  return {
    type: USER_CHANGE_ROL_PETITION_LIST_ERROR,
    payload,
  }
};

// export const changeRolPetitionReject = (payload) => {
//   return {
//     type: USER_CHANGE_ROL_PETITION_REJECT,
//     payload,
//   }
// };

// export const changeRolPetitionRejectSuccess = (payload) => {
//   return {
//     type: USER_CHANGE_ROL_PETITION_REJECT_SUCCESS,
//     payload,
//   }
// };

// export const changeRolPetitionRejectError = (payload) => {
//   return {
//     type: USER_CHANGE_ROL_PETITION_REJECT_ERROR,
//     payload,
//   }
// };

// export const changeRolPetitionApproval = (payload) => {
//   return {
//     type: USER_CHANGE_ROL_PETITION_APPROVAL,
//     payload,
//   }
// };

// export const changeRolPetitionApprovalSuccess = (payload) => {
//   return {
//     type: USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS,
//     payload,
//   }
// };

// export const changeRolPetitionApprovalError = (payload) => {
//   return {
//     type: USER_CHANGE_ROL_PETITION_APPROVAL_ERROR,
//     payload,
//   }
// };
