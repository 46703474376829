import {
  COMMENT,
  COMMENT_DELETE,
  COMMENT_LIST,
  COMMENT_SUCCESS,
  COMMENT_ERROR,
  COMMENT_LIST_SUCCESS,
  COMMENT_LIST_ERROR,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_ERROR,
} from '../constants';

export const sendComment = (data) => {
  return {
    type: COMMENT,
    payload: { data },
  }
};

export const commentDelete = (data) => {
  return {
    type: COMMENT_DELETE,
    payload: { data },
  }
};

export const commentDeleteSuccess = (data) => {
  return {
    type: COMMENT_DELETE_SUCCESS,
    payload: { data },
  }
};

export const commentDeleteError = (data) => ({
  type: COMMENT_DELETE_ERROR,
  payload: { data },
});

export const getCommentList = (data) => {
  return {
    type: COMMENT_LIST,
    payload: { data },
  }
};

export const getCommentListSuccess = (commentsList) => {
  return {
    type: COMMENT_LIST_SUCCESS,
    payload:  commentsList  ,
  }
};

export const getCommentListError = (data) => {
  return {
    type: COMMENT_LIST_ERROR,
    payload: { data },
  }
};

export const commentSuccess = (data) => {
  return {
    type: COMMENT_SUCCESS,
    payload: { data },
  }
};

export const commentError = (data) => {
  return {
    type: COMMENT_ERROR,
    payload: { data },
  }
};

