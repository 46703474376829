import {
  VOTE_A_POST,
  VOTE_A_POST_SUCCESS,
  VOTE_A_POST_ERROR
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: '',
}

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case VOTE_A_POST:
      return {
        ...state,
        loading: true,
        payload: action.payload
      };

    case VOTE_A_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload
      };

    case VOTE_A_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
}

export default reducer;
