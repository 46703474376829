import {
  SET_ALL_RECORDS,
  SET_ALL_RECORDS_ERROR,
  SET_ALL_RECORDS_SUCCESS
} from "../constants"


export const setAllRecords = (payload) => {
  return {
    type: SET_ALL_RECORDS,
    payload
  }
}

export const setAllRecordsSuccess = (payload) => {
  return {
    type: SET_ALL_RECORDS_SUCCESS,
    payload
  }
}

export const setAllRecordsError = (payload) => {
  return {
    type: SET_ALL_RECORDS_ERROR,
    payload
  }
}
