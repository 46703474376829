// AUTH LOGIN BASIC
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

// AUTH LOGIN WITH SOCIAL
export const LOGIN_USER_SOCIAL         = 'LOGIN_USER_SOCIAL';
export const LOGIN_USER_SOCIAL_SUCCESS = 'LOGIN_USER_SOCIAL_SUCCESS';
export const LOGIN_USER_SOCIAL_ERROR   = 'LOGIN_USER_SOCIAL_ERROR';

// AUTH REGISTER
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

// AUTH FORGOT PASSWORD
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

// AUTH UPDATE PASSWORD
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

// AUTH LOAD USER INFO
export const LOAD_USER_INFO         = 'LOAD_USER_INFO';
export const LOAD_USER_INFO_SUCCESS = 'LOAD_USER_INFO_SUCCESS';
export const LOAD_USER_INFO_ERROR   = 'LOAD_USER_INFO_ERROR';

// AUTH LOGOUT
export const LOGOUT         = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR   = 'LOGOUT_ERROR';

// SIDEBAR
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAMES = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

// POSTS
export const POSTS_GET          = 'POSTS_GET';
export const POSTS_GET_SUCCESS  = 'POSTS_GET_SUCCESS';
export const POSTS_GET_ERROR    = 'POSTS_GET_ERROR';

export const POSTS_SAVE = 'POSTS_SAVE';
export const POSTS_SAVE_SUCCESS = 'POSTS_SAVE_SUCCESS';
export const POSTS_SAVE_ERROR = 'POSTS_SAVE_ERROR';

export const POSTS_LIST = 'POSTS_LIST';
export const POSTS_LIST_SUCCESS = 'POSTS_LIST_SUCCESS';
export const POSTS_LIST_ERROR = 'POSTS_LIST_ERROR';

export const POSTS_DETAIL = 'POSTS_DETAIL';
export const POSTS_DETAIL_SUCCESS = 'POSTS_DETAIL_SUCCESS';
export const POSTS_DETAIL_ERROR = 'POSTS_DETAIL_ERROR';

export const POSTS_DELETE = 'POSTS_DELETE';
export const POSTS_DELETE_SUCCESS = 'POSTS_DELETE_SUCCESS';
export const POSTS_DELETE_ERROR = 'POSTS_DELETE_ERROR';

export const POSTS_LIST_ORDER = "POSTS_LIST_ORDER";
export const POSTS_LIST_SEARCH = "POSTS_LIST_SEARCH";

export const SET_CURRENT_ECOSYSTEM = "SET_CURRENT_ECOSYSTEM";

export const POSTS_GET_SEARCH   = 'POSTS_GET_SEARCH'

// UPDATE POST DATA
export const UPDATE_POST         = 'UPDATE_POST_DATA';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_DATA_SUCCESS';
export const UPDATE_POST_ERROR   = 'UPDATE_POST_DATA_ERROR';


// ROLES
export const ROLE_LIST          = 'ROLE_LIST';
export const ROLE_LIST_SUCCESS  = 'ROLE_LIST_SUCCESS';
export const ROLE_LIST_ERROR    = 'ROLE_LIST_ERROR';

// HELP
export const CONTACT         = 'CONTACT';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_ERROR   = 'CONTACT_ERROR';

// SET_ALL_RECORDS
export const SET_ALL_RECORDS          = 'SET_ALL_RECORDS';
export const SET_ALL_RECORDS_SUCCESS  = 'SET_ALL_RECORDS_SUCCESS';
export const SET_ALL_RECORDS_ERROR    = 'SET_ALL_RECORDS_ERROR';

// COMMENTS
export const COMMENT = 'COMMENT';
export const COMMENT_DELETE           = 'COMMENT_DELETE';
export const COMMENT_DELETE_ERROR     = 'COMMENT_DELETE_ERROR';
export const COMMENT_DELETE_SUCCESS   = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_LIST = 'COMMENT_LIST';
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS';
export const COMMENT_LIST_ERROR = 'COMMENT_LIST_ERROR';

// REACTIONS
export const REACTED_TO = 'REACTED_TO';
export const REACTED_TO_SUCCESS = 'REACTED_TO_SUCCESS';
export const REACTED_TO_ERROR = 'REACTED_TO_ERROR';

export const GET_REACTIONS = 'GET_REACTIONS';
export const GET_REACTIONS_SUCCESS = 'GET_REACTIONS_SUCCESS';
export const GET_REACTIONS_ERROR = 'GET_REACTIONS_ERROR';

// VOTES
export const VOTE_A_POST          = 'VOTE_A_POST';
export const VOTE_A_POST_SUCCESS  = 'VOTE_A_POST_SUCCESS';
export const VOTE_A_POST_ERROR    = 'VOTE_A_POST_ERROR';

// PUBLICATIONS
export const PUBLICATIONS_GET_LIST = 'PUBLICATIONS_GET_LIST';
export const PUBLICATIONS_GET_LIST_SUCCESS = 'PUBLICATIONS_GET_LIST_SUCCESS';
export const PUBLICATIONS_GET_LIST_ERROR = 'PUBLICATIONS_GET_LIST_ERROR';

export const POST_GET         = 'POST_GET';
export const POST_GET_SUCCESS = 'POST_GET_SUCCESS';
export const POST_GET_ERROR   = 'POST_GET_ERROR';

export const PUBLICATIONS_GET_LIST_WITH_FILTER = 'PUBLICATIONS_GET_LIST_WITH_FILTER';
export const PUBLICATIONS_GET_LIST_WITH_ORDER = 'PUBLICATIONS_GET_LIST_WITH_ORDER';
export const PUBLICATIONS_GET_LIST_SEARCH = 'PUBLICATIONS_GET_LIST_SEARCH';

export const PUBLICATIONS_ADD_ITEM = 'PUBLICATIONS_ADD_ITEM';
export const PUBLICATIONS_ADD_ITEM_SUCCESS = 'PUBLICATIONS_ADD_ITEM_SUCCESS';
export const PUBLICATIONS_ADD_ITEM_ERROR = 'PUBLICATIONS_ADD_ITEM_ERROR';

export const POST          = 'POST';
export const POST_SUCCESS  = 'POST_SUCCESS';
export const POST_ERROR    = 'POST_ERROR';

export const DELETE_POST          = 'DELETE_POST';
export const DELETE_POST_SUCCESS  = 'DELETE_POST_SUCCESS';
export const DELETE_POST_ERROR    = 'DELETE_POST_ERROR';

export const PUBLICATIONS_ADD_GENERIC_ITEM          = 'PUBLICATIONS_ADD_GENERIC_ITEM';
export const PUBLICATIONS_ADD_GENERIC_ITEM_SUCCESS  = 'PUBLICATIONS_ADD_GENERIC_ITEM_SUCCESS';
export const PUBLICATIONS_ADD_GENERIC_ITEM_ERROR    = 'PUBLICATIONS_ADD_GENERIC_ITEM_ERROR';

export const POST_SET_STATUS          = 'POST_SET_STATUS';
export const POST_SET_STATUS_SUCCESS  = 'POST_SET_STATUS_SUCCESS';
export const POST_SET_STATUS_ERROR    = 'POST_SET_STATUS_ERROR';

// MEETING TYPES
export const MEETING_TYPE_LIST         = 'MEETING_TYPE_LIST';
export const MEETING_TYPE_LIST_SUCCESS = 'MEETING_TYPE_LIST_SUCCESS';
export const MEETING_TYPE_LIST_ERROR   = 'MEETING_TYPE_LIST_ERROR';

// FILES
export const FILE_MANAGER_GET_FILE_LIST = 'FILE_MANAGER_GET_FILE_LIST';
export const FILE_MANAGER_GET_FILE_LIST_SUCCESS = 'FILE_MANAGER_GET_FILE_LIST_SUCCESS';
export const FILE_MANAGER_GET_FILE_LIST_ERROR = 'FILE_MANAGER_GET_FILE_LIST_ERROR';
export const FILE_MANAGER_UPLOAD_FILE = 'FILE_MANAGER_UPLOAD_FILE';
export const FILE_MANAGER_UPLOAD_FILE_SUCCESS = 'FILE_MANAGER_UPLOAD_FILE_SUCCES';
export const FILE_MANAGER_UPLOAD_FILE_ERROR = 'FILE_MANAGER_UPLOAD_FILE_ERROR';
export const FILE_MANAGER_DELETE_FILE = 'FILE_MANAGER_DELETE_FILE';
export const FILE_MANAGER_DELETE_FILE_SUCCESS = 'FILE_MANAGER_DELETE_FILE_SUCCESS';
export const FILE_MANAGER_DELETE_FILE_ERROR = 'FILE_MANAGER_DELETE_FILE_ERROR';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const FILE_MANAGER_LIST_WITH_ORDER = 'FILE_MANAGER_LIST_WITH_ORDER';
export const FILE_MANAGER_GET_LIST_SEARCH = 'FILE_MANAGER_GET_LIST_SEARCH';

// PROFILE
export const USER_UPLOAD_PROFILE_PICTURE                = 'USER_UPLOAD_PROFILE_PICTURE';
export const USER_UPLOAD_PROFILE_PICTURE_SUCCESS        = 'USER_UPLOAD_PROFILE_PICTURE_SUCCESS';
export const USER_UPLOAD_PROFILE_PICTURE_ERROR          = 'USER_UPLOAD_PROFILE_PICTURE_ERROR';
export const USER_UPDATE_PROFILE_DATA                   = 'USER_UPDATE_PROFILE_DATA';
export const USER_UPDATE_PROFILE_DATA_SUCCESS           = 'USER_UPDATE_PROFILE_DATA_SUCCESS';
export const USER_UPDATE_PROFILE_DATA_ERROR             = 'USER_UPDATE_PROFILE_DATA_ERROR';
export const USER_CHANGE_ROL_PETITION                   = "USER_CHANGE_ROL_PETITION";
export const USER_CHANGE_ROL_PETITION_SUCCESS           = "USER_CHANGE_ROL_PETITION_SUCCESS";
export const USER_CHANGE_ROL_PETITION_ERROR             = "USER_CHANGE_ROL_PETITION_ERROR";

// SUBSCRIPTIONS
export const TO_SUBSCRIBE               = 'TO_SUBSCRIBE';
export const TO_SUBSCRIBE_ERROR         = 'TO_SUBSCRIBE_ERROR';
export const TO_SUBSCRIBE_SUCCESS       = 'TO_SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_SUCCESS          = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR            = 'SUBSCRIBE_ERROR';
export const SUBSCRIBE_DELETE           = 'SUBSCRIBE_DELETE';
export const SUBSCRIPTIONS_LIST         = 'SUBSCRIPTIONS_LIST';
export const SUBSCRIPTIONS_LIST_SUCCESS = 'SUBSCRIPTIONS_LIST';
export const SUBSCRIPTIONS_LIST_ERROR   = 'SUBSCRIPTIONS_LIST';

// ECOSYSTEMS
export const ECOSYSTEMS_LIST          = 'ECOSYSTEMS_LIST';
export const ECOSYSTEMS_LIST_SUCCESS  = 'ECOSYSTEMS_LIST_SUCCESS';
export const ECOSYSTEMS_LIST_ERROR    = 'ECOSYSTEMS_LIST_ERROR';

// ECOSYSTEMS SUBSCRIBERS STATUS
export const ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST          = 'ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST';
export const ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_SUCCESS  = 'ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_SUCCESS';
export const ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_ERROR    = 'ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_ERROR';

// ECOSYSTEMS SUBSCRIBERS
export const ECOSYSTEMS_SUBSCRIBERS_LIST          = 'ECOSYSTEMS_SUBSCRIBERS_LIST';
export const ECOSYSTEMS_SUBSCRIBERS_LIST_SUCCESS  = 'ECOSYSTEMS_SUBSCRIBERS_LIST_SUCCESS';
export const ECOSYSTEMS_SUBSCRIBERS_LIST_ERROR    = 'ECOSYSTEMS_SUBSCRIBERS_LIST_ERROR';

// DELETE USER
export const DELETE_USER          = 'DELETE_USER';
export const DELETE_USER_SUCCESS  = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR    = 'DELETE_USER_ERROR';

// GET USER LIST
export const USER_LIST_GET_LIST         = 'USER_LIST_GET_LIST';
export const USER_LIST_GET_LIST_SUCCESS = 'USER_LIST_GET_LIST_SUCCESS';
export const USER_LIST_GET_LIST_ERROR   = 'USER_LIST_GET_LIST_ERROR';

// CHANGE ROL PETITION LIST
export const USER_CHANGE_ROL_PETITION_LIST         ="USER_CHANGE_ROL_PETITION_LIST"
export const USER_CHANGE_ROL_PETITION_LIST_SUCCESS ="USER_CHANGE_ROL_PETITION_LIST_SUCCESS"
export const USER_CHANGE_ROL_PETITION_LIST_ERROR   ="USER_CHANGE_ROL_PETITION_LIST_ERROR"

// CHANGE ROL PETITION APPROVAL
export const USER_CHANGE_ROL_PETITION_APPROVAL          ="USER_CHANGE_ROL_PETITION_APPROVAL"
export const USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS  ="USER_CHANGE_ROL_PETITION_APPROVAL_SUCCESS"
export const USER_CHANGE_ROL_PETITION_APPROVAL_ERROR    ="USER_CHANGE_ROL_PETITION_APPROVAL_ERROR"

// CHANGE ROL PETITION REJECT
export const USER_CHANGE_ROL_PETITION_REJECT            ="USER_CHANGE_ROL_PETITION_REJECT"
export const USER_CHANGE_ROL_PETITION_REJECT_SUCCESS    ="USER_CHANGE_ROL_PETITION_REJECT_SUCCESS"
export const USER_CHANGE_ROL_PETITION_REJECT_ERROR      ="USER_CHANGE_ROL_PETITION_REJECT_ERROR"

// CHANGE LOCALE
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

// POST TYPES
export const SET_POSTS_TYPES         = 'SET_POSTS_TYPES';
export const SET_POSTS_TYPES_SUCCESS = 'SET_POSTS_TYPES_SUCCESS';
export const SET_POSTS_TYPES_ERROR   = 'SET_POSTS_TYPES_ERROR';

// MARK AS READ NOTIFICATIONS
export const MARK_AS_READ_NOTIFICATIONS          = 'MARK_AS_READ_NOTIFICATIONS';
export const MARK_AS_READ_NOTIFICATIONS_SUCCESS  = 'MARK_AS_READ_NOTIFICATIONS_SUCCESS';
export const MARK_AS_READ_NOTIFICATIONS_ERROR    = 'MARK_AS_READ_NOTIFICATIONS_ERROR';

// NOTIFICATIONS
export const GET_NOTIFICATIONS          = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS  = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR    = 'GET_NOTIFICATIONS_ERROR';

// FILES
export const GET_FILE          = 'GET_FILE';
export const GET_FILE_SUCCESS  = 'GET_FILE_SUCCESS';
export const GET_FILE_ERROR    = 'GET_FILE_ERROR';

// ATTACHMENTS
export const ATTACHMENTS_LIST          = 'ATTACHMENTS_LIST';
export const ATTACHMENTS_LIST_SUCCESS  = 'ATTACHMENTS_LIST_SUCCESS';
export const ATTACHMENTS_LIST_ERROR    = 'ATTACHMENTS_LIST_ERROR';

// ATTACHMENTS
export const SAVE_ATTACHMENTS          = 'SAVE_ATTACHMENTS';
export const SAVE_ATTACHMENTS_SUCCESS  = 'SAVE_ATTACHMENTS_SUCCESS';
export const SAVE_ATTACHMENTS_ERROR    = 'SAVE_ATTACHMENTS_ERROR';

// ATTACHMENTS
export const DELETE_ATTACHMENTS          = 'DELETE_ATTACHMENTS';
export const DELETE_ATTACHMENTS_SUCCESS  = 'DELETE_ATTACHMENTS_SUCCESS';
export const DELETE_ATTACHMENTS_ERROR    = 'DELETE_ATTACHMENTS_ERROR';

// FOLDERS
export const CREATE_FOLDER          = 'CREATE_FOLDER';
export const CREATE_FOLDER_SUCCESS  = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_ERROR    = 'CREATE_FOLDER_ERROR';
