import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';
import { MEETING_TYPE_LIST } from '../constants';
import {
  getMeetingTypeListError,
  getMeetingTypeListSuccess
} from './actions';

const meetingTypeListRequestAsync = async () => {
  const request = await apiRequest({
    api: 'GetMetingType',
    props: null,
  })
    .then((response) => response)
    .catch((error) => error);
  return request;
};

function* getMeetingTypeList() {
  const state = yield select();
  const loading = state?.meeting?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(meetingTypeListRequestAsync);
    if (request.status) {
      yield put(getMeetingTypeListSuccess(request.response ?? []));
    } else {
      yield put(getMeetingTypeListError(request.Error));
    }
  } catch (error) {
    yield put(getMeetingTypeListError(error));
  }
}

export function* watchGetMeetingTypeList() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(MEETING_TYPE_LIST, getMeetingTypeList);
}

export default function* rootSaga() {
  yield all([fork(watchGetMeetingTypeList)]);
}
