import {
  POST,
  POST_SUCCESS,
  POST_ERROR,
  POSTS_GET,
  POSTS_GET_ERROR,
  POSTS_GET_SUCCESS,
  SET_CURRENT_ECOSYSTEM,
  POST_GET,
  POST_GET_SUCCESS,
  POST_GET_ERROR,
  POST_SET_STATUS,
  POST_SET_STATUS_SUCCESS,
  POST_SET_STATUS_ERROR,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  UPDATE_POST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_ERROR,
} from "../constants";

const INIT_STATE = {
  error: '',
  loading: false,
  posts: null,
  currentEcosystem: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // BEGIN SAVE POST
    case POST:
      return {
        ...state,
        loading: true
      };

    case POST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
      };

    case POST_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload
      };
    // ENDING SAVE POST

    case POSTS_GET:
      return {
        ...state,
        loading: true,
      };

    case POSTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        posts: action.payload,
      };

    case POSTS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        post: null
      };

    case SET_CURRENT_ECOSYSTEM:
      return {
        ...state,
        currentEcosystem: action.payload
      };

    case POST_GET:
      return {
        ...state,
        loading: true,
        post: null,
      };

    case POST_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.payload,
      };

    case POST_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        post: null,
      };

    case POST_SET_STATUS:
      return {
        ...state,
        loading: true
      };

    case POST_SET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case POST_SET_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case DELETE_POST:
      return {
        ...state,
        loading: true,
        postId: action.payload
      };

    case DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_POST:
      return {
        ...state,
        loading: true,
        payload: action.payload
      };

    case UPDATE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: null,
        error: ''
      };

    case UPDATE_POST_ERROR:
      return {
        ...state,
        loading: false,
        payload: null,
        error: action.payload.error
      };

    default:
      return { ...state };
  }
};

export default reducer;
