import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';

import {
  USER_UPDATE_PROFILE_DATA,
  USER_UPLOAD_PROFILE_PICTURE,
  USER_CHANGE_ROL_PETITION,
  USER_CHANGE_ROL_PETITION_LIST,
} from '../constants';
import {
  uploadProfilePictureSuccess,
  uploadProfilePictureError,
  updateProfileDataSuccess,
  updateProfileDataError,
  changeRolPetitionError,
  changeRolPetitionSuccess,
  changeRolPetitionListSuccess,
  changeRolPetitionListError,
} from './actions';
import { valuesToArray } from '../../helpers/utils'

// Update photo profile request
const uploadProfilePictureRequestAsync = async (payload) => {
  const request = await apiRequest({
    api: 'UpdateProfile',
    props: payload,
    })
    .then((response) => response)
    .catch((error) => error);
  return request;
};

// Update photo profile
function* uploadProfilePicture({payload}) {
  const state = yield select();
  const loading = state?.profile?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(
      uploadProfilePictureRequestAsync,
      payload,
    );
    if (response.status) {
      yield put(uploadProfilePictureSuccess());
    } else {
      yield put(uploadProfilePictureError('An error ocurred'));
    }
  } catch (error) {
    yield put(uploadProfilePictureError(error));
  }
}

// Watch update photo profile
export function* watchUploadProfilePicture() {
  yield takeEvery(USER_UPLOAD_PROFILE_PICTURE, uploadProfilePicture);
}

// Update profile data request
const updateProfileDataAsync = async (payload) => {
  const request = await apiRequest({
    api: 'UpdateProfile',
    props: payload,
    })
    .then((response) => response)
    .catch((error) => error);
  return request;
};

// Update profile data
export function* updateProfileData({ payload }) {
  const values = valuesToArray(payload.data);
  const state = yield select();
  const loading = state?.profile?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(updateProfileDataAsync, {
      userId: payload.userId,
      data: values,
    });
    if (response.status) {
      yield put(updateProfileDataSuccess('Data updated'));
    }
  } catch (error) {
    yield put(updateProfileDataError('An error ocurred'));
  }
}

// Watch update profile data
export function* watchUpdateProfileData() {
  yield takeEvery(USER_UPDATE_PROFILE_DATA, updateProfileData);
}

// Change rol petition
export function* changeRolPetition({payload}) {
  const state = yield select();
  const loading = state?.profile?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'ChangeUserRolPetition',
      props: payload.key,
    });

    const { response, status } = request;

    if (status) {
      yield put(changeRolPetitionSuccess(response));
    } else {
      yield put(changeRolPetitionError('El usuario ya tiene una solicitud pendiente'));
    }
  } catch (error) {
    yield put(changeRolPetitionError(error));
  }
}

// Watch change rol petition
export function* watchChangeRolPetition() {
  yield takeEvery(USER_CHANGE_ROL_PETITION, changeRolPetition);
}

export function* changeRolPetitionList({payload}) {
  const state = yield select();
  const loading = state?.profile?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'ChangeUserRolPetitionList',
      props: payload ?? null,
    });
    if (request.status) {
      yield put(changeRolPetitionListSuccess(request.response));
    } else {
      yield put(changeRolPetitionListError(request.Error.errorCode));
    }
  } catch (error) {
    yield put(changeRolPetitionListError(error));
  }
}

export function* watchChangeRolPetitionList() {
  yield takeEvery(USER_CHANGE_ROL_PETITION_LIST, changeRolPetitionList);
}

export default function* rootSaga() {
  yield all([
    fork(watchUploadProfilePicture),
    fork(watchUpdateProfileData),
    fork(watchChangeRolPetition),
    fork(watchChangeRolPetitionList),
  ]);
}
