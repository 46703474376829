import {
  ROLE_LIST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_ERROR
} from '../constants';

export const getRoleList = () => {
  return {
    type: ROLE_LIST,
  }
};

export const getRoleListSuccess = (items) => ({
  type: ROLE_LIST_SUCCESS,
  payload: items,
});

export const getRoleListError = (error) => ({
  type: ROLE_LIST_ERROR,
  payload: error,
});
