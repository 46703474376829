const ADMIN = 'general-administrator';
const AGENT = 'agent-pebur2025';
const AGENT_PROVINCIAL = 'provincial-social-economic-agent';
const CITIZEN= 'citizen';

const REPOPULATION = 'repopulation-and-quality-of-life';
const BUSINESS = 'business-and-employment-development';
const TRANSITION_ENERGY = 'green-transition-energy-and-environment';
const TRANSITION_BIO_ECONOMY = 'green-transition-bio-economy';
const TOURISM_DEVELOPMENT = 'tourism-and-heritage-development';
const SMART_TERRITORY = 'smart-territory-and-governance';

export const HOME = 'home';
export const PROFILE = 'profile';
export const PREFERENCES = 'preferences';
export const NOTIFICATIONS = 'notifications';
export const MANAGE_USERS = 'manage_users';
export const USERS = 'users';
export const FAGS = 'faqs';
export const LOGOUT = 'logout';
export const MY_ACCOUNT = 'my-account';
export const COLLABORATIVE_PROCESSES = 'collaborative_processes';
export const FILES = 'files';
export const FILES_ES_PAGE = 'files';
export const PUBLICATIONS = 'publications';

export const SUBSCRIBED = 'subscribed';

export const ROLES_KEYS = {
  ADMIN,
  AGENT,
  AGENT_PROVINCIAL,
  CITIZEN,
};

export const ECOSYSTEMS_KEY = {
  REPOPULATION,
  BUSINESS,
  TRANSITION_ENERGY,
  TRANSITION_BIO_ECONOMY,
  TOURISM_DEVELOPMENT,
  SMART_TERRITORY,
}

export const MENU_KEYS = {
  HOME,
  PROFILE,
  PREFERENCES,
  NOTIFICATIONS,
  MANAGE_USERS,
  FAGS,
  LOGOUT,
  MY_ACCOUNT,
  COLLABORATIVE_PROCESSES,
  FILES,
  PUBLICATIONS
}

export const PERMISSIONS_KEYS = {
  COLLABORATIVE_PROCESSES,
  PUBLICATIONS,
  FILES,
}

export const COLLABORATIVE_PROCESSES_PAGE = 'collaborative-process';
export const PUBLICATIONS_PAGE = 'publications';
export const SEARCH_PAGE = 'search';
export const FILES_PAGE = 'files';
export const COLLABORATIVE_PROCESSES_PERMISSION = 'collaborative_processes';
export const PUBLICATIONS_PERMISSION = 'publications';
export const FILES_PERMISSION = 'files';

export const PAGES_KEYS_TO_PERMISSIONS = {}

PAGES_KEYS_TO_PERMISSIONS[COLLABORATIVE_PROCESSES_PAGE] = COLLABORATIVE_PROCESSES_PERMISSION;
PAGES_KEYS_TO_PERMISSIONS[PUBLICATIONS_PAGE] = PUBLICATIONS_PERMISSION;
PAGES_KEYS_TO_PERMISSIONS[FILES_PAGE] = FILES_PERMISSION;
PAGES_KEYS_TO_PERMISSIONS[FILES_ES_PAGE] = FILES_PERMISSION;

export default ROLES_KEYS;

export const PUBLICATIONS_POST_TYPES_IDS = [2, 3, 4, 5];

export const ADMIN_ARRAY_ROLES_KEYS = [
  ROLES_KEYS.ADMIN
];

export const ALL_ARRAY_ROLES_KEYS = [
  ROLES_KEYS.ADMIN,
  ROLES_KEYS.AGENT,
  ROLES_KEYS.AGENT_PROVINCIAL,
  ROLES_KEYS.CITIZEN,
];

export const ADMIN_AND_AGENT_ARRAY_ROLES_KEYS = [
  ROLES_KEYS.ADMIN,
  ROLES_KEYS.AGENT,
];

// Google Constants
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const GOOGLE_LOGIN_SCOPE = process.env.REACT_APP_GOOGLE_LOGIN_SCOPE
export const GOOGLE_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY
export const GOOGLE_SECRET_KEY = process.env.REACT_APP_GOOGLE_SECRET_KEY

// SIDEBAR
export const DEFAULT_MENU_TYPE = 'menu-default';
export const SUB_HIDDEN_BREAKPOINT = 1440;
export const MENU_HIDDEN_BREAK_POINT = 768;

export const CDN_URL = process.env.REACT_APP_CDN_URL

// Locales
export const DEFAULT_LOCALE = 'es';
export const LOCALES = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
];

// MAX_CONTENT_PUBLICATION_LENGTH
export const MAX_CONTENT_PUBLICATION_LENGTH = 500;

export const MENU_HIDDEN_BREAKPOINT = 768;
