import {
  DEFAULT_MENU_TYPE,
  SUB_HIDDEN_BREAKPOINT,
  MENU_HIDDEN_BREAK_POINT,
} from '../../constants/globals';

import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAMES,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
} from '../constants';

const INIT_STATE = {
  containerClassnames: DEFAULT_MENU_TYPE,
  subHiddenBreakpoint: SUB_HIDDEN_BREAKPOINT,
  menuHiddenBreakpoint: MENU_HIDDEN_BREAK_POINT,
  menuClickCount: 0,
  selectedMenuHasSubItems: DEFAULT_MENU_TYPE === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

const reducer =  (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return {
        ...state,
        selectedMenuHasSubItems: action.payload
      };

    case MENU_SET_CLASSNAMES:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };

    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };

    case MENU_CONTAINER_ADD_CLASSNAMES:
      return {
        ...state,
        containerClassnames: action.payload
      };

    case MENU_CHANGE_DEFAULT_CLASSES:
      return {
        ...state,
        containerClassnames: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default reducer;
