import {
  PUBLICATIONS_GET_LIST,
  PUBLICATIONS_GET_LIST_SUCCESS,
  PUBLICATIONS_GET_LIST_ERROR,
  POST_GET,
  POST_GET_SUCCESS,
  POST_GET_ERROR,
  PUBLICATIONS_ADD_ITEM,
  PUBLICATIONS_ADD_ITEM_SUCCESS,
  PUBLICATIONS_ADD_ITEM_ERROR,
  POST,
  POST_SUCCESS,
  POST_ERROR,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  PUBLICATIONS_ADD_GENERIC_ITEM,
  PUBLICATIONS_ADD_GENERIC_ITEM_SUCCESS,
  PUBLICATIONS_ADD_GENERIC_ITEM_ERROR,
  // PUBLICATION_SET_STATUS,
  // PUBLICATION_SET_STATUS_SUCCESS,
  // PUBLICATION_SET_STATUS_ERROR,
  PUBLICATIONS_GET_LIST_SEARCH
} from '../constants';

const INIT_STATE = {
  allPublications: [],
  publicationsItems: [],
  searchKeyword: '',
  error: '',
  loaded: false,
  loading: false,
  post: null,
};

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case PUBLICATIONS_GET_LIST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case PUBLICATIONS_GET_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        allPublications: action.payload,
        publicationsItems: action.payload

      };

    case PUBLICATIONS_GET_LIST_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload,
        allPublications: [],
        publicationsItems:[]
      };

    case POST_GET:
      return {
        ...state,
        loading: true,
        post: null,
      };

    case POST_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.payload,
      };

    case POST_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        post: null,
      };

    case DELETE_POST:
      return {
        ...state,
        loading: true,
        postId: action.payload
      };

    case DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PUBLICATIONS_ADD_ITEM:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case PUBLICATIONS_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        publicationsItems: action.payload,
      };

    case PUBLICATIONS_ADD_ITEM_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload
      };

    case PUBLICATIONS_ADD_GENERIC_ITEM:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case PUBLICATIONS_ADD_GENERIC_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        publicationsItems: action.payload,
      };

    case PUBLICATIONS_ADD_GENERIC_ITEM_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload
      };

    case POST:
      return { ...state, loaded: false, loading: true };

    case POST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        publicationsItems: action.payload,
      };

    case POST_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload
      };
    // case PUBLICATION_SET_STATUS:
    //   return { ...state, loaded: false, loading: true };

    // case PUBLICATION_SET_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     loaded: true,
    //     loading: false,
    //     publicationsItems: action.payload,
    //   };

    // case PUBLICATION_SET_STATUS_ERROR:
    //   return {
    //     ...state,
    //     loaded: true,
    //     loading: false,
    //     error: action.payload
    //   };
    case PUBLICATIONS_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, publicationsItems: state.allPublications };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allPublications.filter(
        (item) => {
        return item.fields[1].value.toLowerCase().indexOf(keyword) > -1
        }
      );
      return {
        ...state,
        loaded: true,
        publicationsItems: searchItems,
        searchKeyword: action.payload,
      }
    default:
      return { ...state };
  }
};

export default reducer;
