import {
  ECOSYSTEMS_LIST,
  ECOSYSTEMS_LIST_SUCCESS,
  ECOSYSTEMS_LIST_ERROR,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_SUCCESS,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_ERROR,
  ECOSYSTEMS_SUBSCRIBERS_LIST,
  ECOSYSTEMS_SUBSCRIBERS_LIST_SUCCESS,
  ECOSYSTEMS_SUBSCRIBERS_LIST_ERROR,
} from '../constants';

const INIT_STATE = {
  loading: false,
  ecosystems: [],
  ecosystemsSubscribersStatus: [],
  ecosystemsSubscribers: [],
};

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case ECOSYSTEMS_LIST:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };

    case ECOSYSTEMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ecosystems: action.payload,
        error: '',
      };

    case ECOSYSTEMS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        ecosystems: []
      };

    case ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };

    case ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ecosystemsSubscribersStatus: action.payload,
        error: '',
      };

    case ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        ecosystemsSubscribersStatus: [],
      };

    case ECOSYSTEMS_SUBSCRIBERS_LIST:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };

    case ECOSYSTEMS_SUBSCRIBERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ecosystemsSubscribers: action.payload,
        error: '',
      };

    case ECOSYSTEMS_SUBSCRIBERS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        ecosystemsSubscribers: [],
      };
    default:
      return { ...state };
  }
};

export default reducer;
