import {
  all,
  fork,
  put,
  takeEvery
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';
import {
  CONTACT,
} from '../constants';
import {
  contactSuccess,
  contactError,
} from './actions';

export function* contact({ payload }) {
  try {
    const request = yield apiRequest({
      api: 'Contact',
      props: {
        name: payload.nombre,
        email: payload.email,
        roleId: payload.rol,
        message: payload.mensaje,
      },
    });
    if (request.status) {
      yield put(contactSuccess(request));
    } else {
      yield put(contactError(request));
    }
  } catch (error) {
    yield put(contactError(error));
  }
}

export function* watchContact() {
  yield takeEvery(CONTACT, contact);
}

export default function* rootSaga() {
  yield all([
    fork(watchContact),
  ]);
}
