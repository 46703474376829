import {
  POST,
  POST_SUCCESS,
  POST_ERROR,
  POSTS_GET,
  POSTS_GET_ERROR,
  POSTS_GET_SUCCESS,
  POSTS_GET_SEARCH,
  POSTS_SAVE,
  POSTS_SAVE_ERROR,
  POSTS_SAVE_SUCCESS,
  SET_CURRENT_ECOSYSTEM,
  POST_GET,
  POST_GET_SUCCESS,
  POST_GET_ERROR,
  POST_SET_STATUS,
  POST_SET_STATUS_SUCCESS,
  POST_SET_STATUS_ERROR,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  UPDATE_POST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_ERROR,
} from "../constants"

// BEGIN SAVE POST
export const post = (payload) => {
  return {
    type: POST,
    payload,
  }
};

export const postSuccess = (payload) => {
  return {
    type: POST_SUCCESS,
    payload,
  }
};

export const postError = (payload) => {
  return {
    type: POST_ERROR,
    payload,
  }
};
// ENDING SAVE POST

export const getPosts = (payload) => {
  return {
    type: POSTS_GET,
    payload,
  }
}

export const getPostsSuccess = (payload) => {
  return {
    type: POSTS_GET_SUCCESS,
    payload,
  }
}

export const getPostsError = (payload) => {
  return {
    type: POSTS_GET_ERROR,
    payload,
  }
}

export const getPostSearch = (payload) => {
  return {
    type: POSTS_GET_SEARCH,
    payload: payload,
  }
};

export const postSave = (data, history) => {
  return {
    type: POSTS_SAVE,
    loading: true,
    payload: { data, history },
  }
};

export const postSaveSuccess = (data, history) => {
  return {
    type: POSTS_SAVE_SUCCESS,
    loading: false,
    payload: { data, history },
  }
};

export const postSaveError = (data, history) => {
  return {
    type: POSTS_SAVE_ERROR,
    loading: false,
    payload: { data, history },
  }
};

export const setCurrentEcosystem = (payload) => {
  return {
    type: SET_CURRENT_ECOSYSTEM,
    loading: true,
    payload,
  }
};

export const getPost = (payload) => {
  return {
    type: POST_GET,
    payload,
  }
};

export const getPostSuccess = (payload) => {
  return {
    type: POST_GET_SUCCESS,
    payload,
  }
};

export const getPostError = (payload) => {
  return {
    type: POST_GET_ERROR,
    payload,
  }
};

export const setPostStatus = (payload) => {
  return {
    type:  POST_SET_STATUS,
    payload
  }
};

export const setPostStatusSuccess = (payload) => {
  return {
    type:  POST_SET_STATUS_SUCCESS,
    payload
  }
};

export const setPostStatusError = (payload) => {
  return {
    type:  POST_SET_STATUS_ERROR,
    payload
  }
};

export const deletePost = (payload) => {
  return {
    type: DELETE_POST,
    payload,
  }
};

export const deletePostSuccess = (payload) => {
  return {
    type: DELETE_POST_SUCCESS,
    payload,
  }
};

export const deletePostError = (payload) => {
  return {
    type: DELETE_POST_ERROR,
    payload,
  }
};

export const updatePost = (payload) => {
  return {
    type: UPDATE_POST,
    loading: true,
    payload,
  }
};

export const updatePostSuccess = (payload) => {
  return {
    type: UPDATE_POST_SUCCESS,
    loading: false,
    payload,
  }
};

export const updatePostError = (payload) => {
  return {
    type: UPDATE_POST_ERROR,
    loading: false,
    payload,
  }
};
