
import {
  MEETING_TYPE_LIST,
  MEETING_TYPE_LIST_SUCCESS,
  MEETING_TYPE_LIST_ERROR,
} from '../constants';

export const getMeetingTypeList = (payload) => {
  return {
    type: MEETING_TYPE_LIST,
    payload,
  }
};

export const getMeetingTypeListSuccess = (payload) => {
  return {
    type: MEETING_TYPE_LIST_SUCCESS,
    payload,
  }
};

export const getMeetingTypeListError = (payload) => {
  return {
    type: MEETING_TYPE_LIST_ERROR,
    payload,
  }
};
