import {
  FILE_MANAGER_GET_FILE_LIST,
  FILE_MANAGER_GET_FILE_LIST_SUCCESS,
  FILE_MANAGER_GET_FILE_LIST_ERROR,
  FILE_MANAGER_UPLOAD_FILE,
  FILE_MANAGER_UPLOAD_FILE_SUCCESS,
  FILE_MANAGER_UPLOAD_FILE_ERROR,
  FILE_MANAGER_DELETE_FILE,
  FILE_MANAGER_DELETE_FILE_SUCCESS,
  FILE_MANAGER_DELETE_FILE_ERROR,
  FILE_MANAGER_LIST_WITH_ORDER,
  FILE_MANAGER_GET_LIST_SEARCH,
  CLEAR_STATUS,
  GET_FILE,
  GET_FILE_ERROR,
  GET_FILE_SUCCESS
} from '../constants';

export const getFileList = (payload) => {
  return {
    type: FILE_MANAGER_GET_FILE_LIST,
    payload
  }
};

export const getFileListSuccess = (payload) => {
  return {
    type: FILE_MANAGER_GET_FILE_LIST_SUCCESS,
    payload,
  }
};

export const getFileListError = (payload) => {
  return {
    type: FILE_MANAGER_GET_FILE_LIST_ERROR,
    payload,
  }
};

export const uploadFile = (payload) => {
  return {
    type: FILE_MANAGER_UPLOAD_FILE,
    payload
  }
};

export const uploadFileSuccess = (payload) => {
  return {
    type: FILE_MANAGER_UPLOAD_FILE_SUCCESS,
    payload
  }
};

export const uploadFileError = (payload) => {
  return {
    type: FILE_MANAGER_UPLOAD_FILE_ERROR,
    payload,
  }
};

// TODO: To after
export const deleteFile = (fileId, idEco ) => ({
  type: FILE_MANAGER_DELETE_FILE,
  payload: { fileId, idEco }
});

export const deleteFileSuccess = () => ({
  type: FILE_MANAGER_DELETE_FILE_SUCCESS,
});

export const deleteFileError = (error) => ({
  type: FILE_MANAGER_DELETE_FILE_ERROR,
  payload: error,
});

export const clearStatus = () => ({
  type: CLEAR_STATUS
});

export const getFileManagerListWithOrder = (column) => ({
  type: FILE_MANAGER_LIST_WITH_ORDER,
  payload: column,
});

export const getFileManagerListSearch = (keyword) => ({
  type: FILE_MANAGER_GET_LIST_SEARCH,
  payload: keyword,
});

export const getFile = (payload) => {
  return {
    type: GET_FILE,
    payload
  }
};

export const getFileSuccess = (payload) => {
  return {
    type: GET_FILE_SUCCESS,
    payload,
  }
};

export const getFileError = (payload) => {
  return {
    type: GET_FILE_ERROR,
    payload,
  }
};
