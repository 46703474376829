import {
  VOTE_A_POST,
  VOTE_A_POST_SUCCESS,
  VOTE_A_POST_ERROR
} from '../constants';


export const voteAPost = (payload) =>  {
  return {
    type: VOTE_A_POST,
    loading: true,
    payload,
  }
};

export const voteAPostSuccess = (payload) => {
  return {
    type: VOTE_A_POST_SUCCESS,
    loading: false,
    payload,
  }
};

export const voteAPostError = (payload) => {
  return {
    type: VOTE_A_POST_ERROR,
    loading: false,
    payload,
  }
};
