import {
  REACTED_TO,
  REACTED_TO_SUCCESS,
  REACTED_TO_ERROR,
  GET_REACTIONS,
  GET_REACTIONS_SUCCESS,
  GET_REACTIONS_ERROR
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: '',
  reactions: null,
  response: null,
}

const reducer = (state = INIT_STATE, action = { type: null }) => {
  switch (action.type) {
    case REACTED_TO:
      return {
        ...state,
        loading: true,
        payload: action.payload,
        response: null
      };

    case REACTED_TO_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
        response: action.payload
      };

    case REACTED_TO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null
      };

    case GET_REACTIONS:
      return {
        ...state,
        loading: true,
        payload: action.payload
      };

    case GET_REACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        reactions: action.payload
      };

    case GET_REACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
        };

    default:
      return { ...state };
  }}

export default reducer;
