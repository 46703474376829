import {
  ECOSYSTEMS_LIST,
  ECOSYSTEMS_LIST_SUCCESS,
  ECOSYSTEMS_LIST_ERROR,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_SUCCESS,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_ERROR,
  ECOSYSTEMS_SUBSCRIBERS_LIST,
  ECOSYSTEMS_SUBSCRIBERS_LIST_SUCCESS,
  ECOSYSTEMS_SUBSCRIBERS_LIST_ERROR,
} from '../constants';

export const getAllEcosystemsList = (payload) => {
  return {
    type: ECOSYSTEMS_LIST,
    loading: true,
    payload,
  }
};

export const getAllEcosystemsListSuccess = (payload) => {
  return {
    type: ECOSYSTEMS_LIST_SUCCESS,
    loading: false,
    payload,
  }
};

export const getAllEcosystemsListError = (payload) => {
  return {
    type: ECOSYSTEMS_LIST_ERROR,
    loading: false,
    payload,
  }
};

export const getAllEcosystemsSubscribersStatusList = (payload) => {
  return {
    type: ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST,
    loading: true,
    payload,
  }
};

export const getAllEcosystemsSubscribersStatusListSuccess = (payload) => {
  return {
    type: ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_SUCCESS,
    loading: false,
    payload,
  }
};

export const getAllEcosystemsSubscribersStatusListError = (payload) => {
  return {
    type: ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST_ERROR,
    loading: false,
    payload,
  }
};

export const getAllEcosystemsSubscribersList = (payload) => {
  return {
    type: ECOSYSTEMS_SUBSCRIBERS_LIST,
    loading: true,
    payload,
  }
};

export const getAllEcosystemsSubscribersListSuccess = (payload) => {
  return {
    type: ECOSYSTEMS_SUBSCRIBERS_LIST_SUCCESS,
    loading: false,
    payload,
  }
};

export const getAllEcosystemsSubscribersListError = (payload) => {
  return {
    type: ECOSYSTEMS_SUBSCRIBERS_LIST_ERROR,
    loading: false,
    payload,
  }
};
