// Own
import { readLocal, validateUserToken } from './LocalStorage';

const apiRest = process.env.REACT_APP_API;

const getRequestBody = (data, token = null) => {
  let localToken = readLocal('sessionToken');
  if (token) {
    localToken = token;
  }
  const requestBody = {
    api: data.api,
    props: data.props,
    token: localToken,
  };

  return JSON.stringify(requestBody);
};
/**
 * Esta deberia ser la respuesta para la data del usuario
 * export const currentUser = {
   id: 1,
   title: 'Sarah Kortney', <- nombre de usuario
   img: '/assets/img/profiles/l-1.jpg' <- Foto de perfil,
   date: 'Last seen today 15:24',
   role: UserRole.Admin,
};
 */

const apiRequest = async (data, token = null) => {
  let fetchResult = {
    status: false,
    response: null,
  };

  let fetchRequest;
  const requestBody = getRequestBody(data, token);
  try {
    fetchRequest = await fetch(apiRest, {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: requestBody,
    });

    fetchResult = await fetchRequest.json();
  } catch (error) {
    console.warn('Api request error');
    console.error({ error });
  }

  await validateUserToken(fetchResult);

  return fetchResult;
};

export default apiRequest;
