import {
  all,
  fork,
  put,
  select,
  takeEvery,
  call,
} from "redux-saga/effects";

// Own
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS
} from "../constants";
import apiRequest from "../../helpers/apiHelper";
import {
  getNotificationsSuccess,
  markAsReadNotificationsError,
  markAsReadNotificationsSuccess
} from "./actions";
import { getPostsError, setAllRecords, setAllRecordsError, setAllRecordsSuccess } from "../actions";

export function* markAsReadNotifications({payload}) {
  const state = yield select();
  const loading = state?.notifications?.loading;
  if (!loading) {
    return;
  }

  try {
    const request = yield apiRequest({
      "api": "MarkAsReadNotification",
      "props": payload
    });

    if (request.status){
      yield put(markAsReadNotificationsSuccess())
    }else{
      yield put(markAsReadNotificationsError(request.Error.errorCode))
    }

  } catch (error) {
    yield put(markAsReadNotificationsError(error))
  }
}

export function* watchMarkAsReadNotifications() {
  yield takeEvery(MARK_AS_READ_NOTIFICATIONS, markAsReadNotifications)
}

const getNotificationsAsync = async (payload) => {

  const request = await apiRequest({
    api: 'GetNotifications',
    props: payload,
  })
  .then((response) => response)
  .catch((error) => error);

  return request;
};

function* getNotificationsList({payload}) {
  const state = yield select();
  const loading = state?.notifications?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(getNotificationsAsync, payload);
    if (request.status) {
      yield put(getNotificationsSuccess(request.response ?? []));
      yield put(setAllRecords());
      yield put(setAllRecordsSuccess(request['all-records']));
    } else {
      yield put(getPostsError(request.Error));
      yield put(setAllRecordsError(0));
    }
  } catch (error) {
    yield put(getPostsError(error));
    yield put(setAllRecordsError(0));
  }
}

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationsList);
}

export default function* rootSaga() {
  yield all([
    fork(watchMarkAsReadNotifications),
    fork(watchGetNotifications),
  ]);
}
