import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS_ERROR,
  MARK_AS_READ_NOTIFICATIONS_SUCCESS
} from "../constants"

export const markAsReadNotifications = (payload) => {
  return {
    type: MARK_AS_READ_NOTIFICATIONS,
    loading: true,
    payload,
  }
}

export const markAsReadNotificationsSuccess = (payload) => {
  return {
    type: MARK_AS_READ_NOTIFICATIONS_SUCCESS,
    loading: false,
    payload,
  }
}

export const markAsReadNotificationsError = (payload) => {
  return {
    type: MARK_AS_READ_NOTIFICATIONS_ERROR,
    loading: false,
    payload,
  }
}

export const getNotifications = (payload) => {
  return {
    type: GET_NOTIFICATIONS,
    payload,
  }
}

export const getNotificationsSuccess = (payload) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload,
  }
}

export const getNotificationsError = (payload) => {
  return {
    type: GET_NOTIFICATIONS_ERROR,
    payload,
  }
}
