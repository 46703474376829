import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';

// Own
import {
  LOGIN_USER,
  LOGIN_USER_SOCIAL,
  REGISTER_USER,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  LOAD_USER_INFO,
  LOGOUT,
} from '../constants';
import {
  basicLoginError,
  basicLoginSuccess,
  forgotPasswordError,
  forgotPasswordSuccess,
  loadUserInfoError,
  loadUserInfoSuccess,
  loginWithSocialError,
  loginWithSocialSuccess,
  logoutSuccess,
  registerError,
  registerSuccess,
  updatePasswordError,
  updatePasswordSuccess,
} from './actions';
import apiRequest from '../../helpers/apiHelper';
import { setToken } from '../../helpers/session-utils';
import { localClear } from '../../helpers/LocalStorage';

// BASIC LOGIN
const basicLoginAsync = async (email, password) => {
  const request = await apiRequest({
    api: 'UserLogin',
    props: { email, pass: password },
  });
  return request;
}

// BASIC LOGIN
function* basicLogin({ payload }) {
  const { email, password } = payload.user;
  // const { navigate } = payload;
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(
      basicLoginAsync,
      email,
      password);
    if (request.status) {
      // TODO: Set token
      const { response } = request;
      yield(setToken(response));
      yield(put(basicLoginSuccess()));
      // TODO: Get user information
    } else {
      yield put(
        basicLoginError(request?.Error?.errorMessage ?? 'Error de server')
      );
    }
  } catch (error) {
    yield put(basicLoginError(error));
  }
}

// BASIC LOGIN
export function* watchBasicLogin() {
  yield takeEvery(LOGIN_USER, basicLogin);
}

// LOGIN WITH SOCIAL
const loginWithSocialAsync = async (payload) => {
  const request = await apiRequest({
    api: 'UserLoginSocial',
    props: payload,
  });

  return request;
}

// LOGIN WITH SOCIAL
function* loginWithSocial({ payload }){
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(loginWithSocialAsync, payload);
    if (request.status) {
      const { response } = request;
      yield(setToken(response));
      yield put(loginWithSocialSuccess());
      // yield call(reloadUserInfo);
    } else {
      yield put(
        loginWithSocialError(request?.Error?.errorMessage ?? 'Error de server')
      );
    }
  } catch (error) {
    yield put(loginWithSocialError(error));
  }
}

// LOGIN WITH SOCIAL
export function* watchLoginWithSocial() {
  yield takeEvery(LOGIN_USER_SOCIAL, loginWithSocial);
}

const basicRegisterAsync = async (values) => {
  const request =  await apiRequest({
    api: 'RegisterUser',
    props: values,
  }).catch((error) => error);
  return request;
}

function* basicRegister({ payload }) {
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(
      basicRegisterAsync,
      payload
    );

    if (request.status) {
      const { response } = request;
      // TODO: Create function to login user
      yield(setToken(response));
      yield put(registerSuccess(response));
    } else {
      const { Error } = request;
      if (Error) {
        const { errorMessage } = Error;
        if (errorMessage) {
          yield put(registerError(errorMessage));
        }
      }
    }
  } catch (error) {
    yield put(registerError(error));
  }
}

export function* watchBasicRegister() {
  yield takeEvery(REGISTER_USER, basicRegister);
}

const forgotPasswordAsync = async (email) => {
  const request = await apiRequest({
    api: 'ForgotPassword',
    props: {
      email,
    },
  }).catch((error) => error);
  return request;
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const updatePasswordAsync = async (payload) => {
  const { token, data } = payload;
  const request = await apiRequest({
    api: 'UpdateProfile',
    props: {
      data,
    },
  }, token)
    .then((response) => response)
    .catch((error) => error);
  return request;
};

function* updatePassword({payload}){
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(updatePasswordAsync, payload)
    if (request?.status) {
      // yield localClear();
      yield put(updatePasswordSuccess());
    }
  } catch (error) {
    yield put(updatePasswordError(error));
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

// GET USER INFORMATION
const loadUserInformationAsync = async () => {
  const request = await apiRequest({
    api: 'GetMyBasicProfile',
    props: null,
  });
  return request;
}

function* loadUserInfo(){
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(loadUserInformationAsync);
    yield put(loadUserInfoSuccess(request.response));
  } catch (error) {
    yield put(loadUserInfoError(error));
  }
}

export function* watchLoadUserInfo() {
  yield takeEvery(LOAD_USER_INFO, loadUserInfo);
}

// AUTH LOGOUT
const logoutAsync = async () => {
  // TODO: Call logout of backend
  await localClear();
};

function* logout() {
  const state = yield select();
  const loading = state?.auth?.loading;
  if (!loading) {
    return;
  }
  yield put(logoutSuccess());
  yield call(logoutAsync);
  setToken();
}

export function* watchLogout() {
  yield takeEvery(LOGOUT, logout);
}

export default function* rootSaga() {
  yield all([
    fork(watchBasicLogin),
    fork(watchLoginWithSocial),
    fork(watchForgotPassword),
    fork(watchLoadUserInfo),
    fork(watchLogout),
    fork(watchBasicRegister),
  ]);
}
