// Import from reduc
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
} from "redux-saga/effects";

// Own
// Import helpers globals
import apiRequest from "../../helpers/apiHelper";
// Import from constants
import {
  ECOSYSTEMS_LIST,
  ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST,
  ECOSYSTEMS_SUBSCRIBERS_LIST,
} from '../constants';
import {
  getAllEcosystemsListError,
  getAllEcosystemsListSuccess,
  getAllEcosystemsSubscribersListError,
  getAllEcosystemsSubscribersListSuccess,
  getAllEcosystemsSubscribersStatusListError,
  getAllEcosystemsSubscribersStatusListSuccess
} from "./action";


const getAllEcosystemsListRequest = async () => {
  const request = await apiRequest({
    api: 'GetAllEcosystems',
    props: null,
  })
  .then((response)=> response)
  .catch((error) => error);

  return request;
}

const getAllEcosystemsSubscribersStatusListRequest = async () => {
  const request = await apiRequest({
    api: 'GetEcosystemsSubcriptorsStatus',
    props: null,
  })
  .then((response)=> response)
  .catch((error) => error);

  return request;
}

const getAllEcosystemsSubscribersListRequest = async () => {
  const request = await apiRequest({
    api: 'GetEcosystemsSubscribers',
    props: null,
  })
  .then((response)=> response)
  .catch((error) => error);

  return request;
}

function* getAllEcosystemsList() {
  const state = yield select();
  const loading = state?.ecosystems?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(getAllEcosystemsListRequest);
    yield put(getAllEcosystemsListSuccess(response.response));
  } catch (error) {
    yield put(getAllEcosystemsListError(error));
  }
}

function* getAllEcosystemsSubscribersStatusList({payload}) {
  const state = yield select();
  const loading = state?.ecosystems?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(getAllEcosystemsSubscribersStatusListRequest, payload);
    yield put(getAllEcosystemsSubscribersStatusListSuccess(response.response));
  } catch (error) {
    yield put(getAllEcosystemsSubscribersStatusListError(error));
  }
}

function* getAllEcosystemsSubscribersList({payload}) {
  const state = yield select();
  const loading = state?.ecosystems?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(getAllEcosystemsSubscribersListRequest, payload);
    yield put(getAllEcosystemsSubscribersListSuccess(response.response));
  } catch (error) {
    yield put(getAllEcosystemsSubscribersListError(error));
  }
}

export function* watchEcosystemsList() {
  yield takeEvery(ECOSYSTEMS_LIST, getAllEcosystemsList);
}

export function* watchEcosystemsSubscribersStatusList() {
  yield takeEvery(ECOSYSTEMS_SUBSCRIBERS_STATUS_LIST , getAllEcosystemsSubscribersStatusList);
}

export function* watchEcosystemsSubscribersList() {
  yield takeEvery(ECOSYSTEMS_SUBSCRIBERS_LIST , getAllEcosystemsSubscribersList);
}


export default function* rootSaga() {
  yield all([
    fork(watchEcosystemsList),
    fork(watchEcosystemsSubscribersStatusList),
    fork(watchEcosystemsSubscribersList),
  ]);
};
