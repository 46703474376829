import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery
} from "redux-saga/effects";

// Own
import {
  POST,
  POSTS_GET,
  POSTS_SAVE,
  POST_GET,
  POST_SET_STATUS,
  DELETE_POST,
  UPDATE_POST,
 } from "../constants";
import {
  deletePostError,
  deletePostSuccess,
  getPostError,
  getPostsError,
  getPostsSuccess,
  getPostSuccess,
  postError,
  postSaveError,
  postSaveSuccess,
  postSuccess,
  setPostStatusError,
  setPostStatusSuccess,
  updatePostError,
  updatePostSuccess
} from "./actions";

// Own
import apiRequest from "../../helpers/apiHelper";
import { setAllRecords, setAllRecordsError, setAllRecordsSuccess } from "../actions";

const postRequest = async (payload) => {
  const request = await apiRequest({
    api: 'SavePostData',
    props: payload
  })
    .then((response) => response)
    .catch((error) => error);
  return request;
}

function* post({ payload }) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(postRequest, payload);
    /* end */
    yield put(postSuccess(response));
  } catch (error) {
    yield put(postError(error));
  }
}

export function* watchPost() {
  yield takeEvery(POST, post);
}

const getPostsListAsync = async (payload) => {
  const {
    postType,
    ecosystemID: ecosystemId,
    page,
    search,
    peerPage,
    orderBy = null,
  } = payload;
  const request = await apiRequest({
    api: 'GetPosts',
    props: {
      filter: {
        postType,
        ecosystemId,
      },
      search,
      page,
      peerPage,
      orderBy,
    },
  })
  .then((response) => response)
  .catch((error) => error);

  return request;
};

function* getPostsList({payload}) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const result = yield call(getPostsListAsync, payload);
    if (result.status) {
      yield put(getPostsSuccess(result.response ?? []));
      yield put(setAllRecords());
      yield put(setAllRecordsSuccess(result['all-records']));
    } else {
      yield put(getPostsError(result.Error));
      yield put(setAllRecordsError(0));
    }
  } catch (error) {
    yield put(getPostsError(error));
    yield put(setAllRecordsError(0));
  }
}

export function* watchGetList() {
  yield takeEvery(POSTS_GET, getPostsList);
}

const postSaveAsync = async ({ fields }) => {
  const request = await apiRequest({
    api: 'SavePostData',
    props: {
      postType: 1,
      postStatus: 1,
      ecosystemID: +fields.ecosystemId,
      fields: {
        title: fields.title,
        actionLine: fields.actionLine,
        dateStart: fields.dateStart,
        dateEnd: fields.dateEnd,
        processPhase: 1,
        ecosystemId: +fields.ecosystemId,
        informationPhaseDetails: fields.informationPhaseDetails,
        informationProcessPhaseDetails: fields.informationProcessPhaseDetails,
        debatePhaseDetails: fields.debatePhaseDetails,
        returnPhaseDetails: fields.returnPhaseDetails,
        trackingPhaseDetails: fields.trackingPhaseDetails,
        fileId: fields.fileId,
        files: fields.files,
      },
    },
  }).catch((error) => error);
  return request;
};

function* postSave({ payload }) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(
      postSaveAsync,
      payload.data
    );
    if (request.status) {
      yield put(postSaveSuccess(request.status));
    }
    if (!request.status) {
      yield put(postSaveError(request?.Error?.errorMessage ?? 'Error'));
      }
  } catch (error) {
    yield put(postSaveError(error));
  }
}

export function* watchPostSave() {
  yield takeEvery(POSTS_SAVE, postSave);
}

// GET POST
const getPostAsync = async (payload) => {
  const request =  await apiRequest({
    api: 'GetPost',
    props: {
      filter: {
        postId: payload,
      },
    },
  })
    .then((response) => response)
    .catch((error) => error);

  return request;
};

function* getPost({ payload }) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield call(getPostAsync, payload);
    if (request.status) {
      yield put(getPostSuccess(request.response[0]));
    } else {
      yield put(getPostError(request.message));
    }
  } catch (error) {
    yield put(getPostError(error));
  }
}

export function* watchGetPost() {
  yield takeEvery(POST_GET, getPost);
}

export function* setPostStatus({payload}) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'ChangePostStatus',
      props: {
        postId: payload.postId,
        statusKey: payload.status
      },
    });

    if (request.status) {
      yield put(setPostStatusSuccess());
    } else {
      yield put(setPostStatusError(request.Error.errorCode));
    }
  } catch (error) {
    yield put(setPostStatusError(error));
  }
}

export function* watchSetStatusPost() {
  yield takeEvery(POST_SET_STATUS, setPostStatus);
}

export function* deletePost({payload} ) {
  const state = yield select();
  const loading = state?.publications?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'DeletePost',
      props: payload.postId,
    });

    if (request.status) {
      yield put(deletePostSuccess(request.status));
    } else {
      yield put(deletePostError(request.Error.errorCode));
    }
  } catch (error) {
    console.error(error)
    yield put(deletePostError(error));
  }
}

export function* watchDeletePost() {
  yield takeEvery(DELETE_POST, deletePost);
}

const updatePostDataAsync = async (payload) => {
  const request = await apiRequest({
    api: 'UpdatePostData',
    props: payload
  })
    .then((response) => response)
    .catch((error) => error);
  return request;
}

function* updatePost({payload}) {
  const state = yield select();
  const loading = state?.posts?.loading;
  if (!loading) {
    return;
  }

  try {
    const request = yield call(
      updatePostDataAsync,
      payload
    );

    yield put(updatePostSuccess());
  } catch (error) {
    yield put(updatePostError(error));
  }

}

export function* watchUpdatePost() {
  yield takeEvery(UPDATE_POST, updatePost)
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetPost),
    fork(watchPost),
    fork(watchSetStatusPost),
    fork(watchDeletePost),
    fork(watchUpdatePost),
  ])
}
