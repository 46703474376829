// Own
import { ROUTE_LOGIN } from "../../constants/routes";

export function readLocal(key) {
  if (!key) {
    return null;
  }

  let value =
    window.sessionStorage.getItem(key) ??
    window.localStorage.getItem(key) ??
    false;

  if (value) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      console.error(e);
    }
  }

  return value;
}

export async function setLocal(key, value, persistant = true) {
  if (!key) {
    return false;
  }

  const valueToWrite = JSON.stringify(value);

  if (persistant === true) {
    window.localStorage.setItem(key, valueToWrite);
  } else {
    window.sessionStorage.setItem(key, valueToWrite);
  }

  return true;
}

export async function localRemove(key, persistant = true) {
  if (persistant === true) {
    window.localStorage.removeItem(key);
  } else {
    window.sessionStorage.removeItem(key);
  }
}

export async function localClear() {
  window.localStorage.clear();
  window.sessionStorage.clear();
}

export async function validateUserToken(result) {
  const { Error } = result;
  if (Error) {
    const { errorCode } = Error;
    if (errorCode === 'invalidUserToken') {
      // Clear local storage
      await localClear();
      // Redirect to index
      window.location.href = ROUTE_LOGIN;
    }
  }
}

export function isLoggedIn() {
  const token = readLocal('sessionToken');
  if (!token) {
    return false;
  }
  return true;
}

export default readLocal;
