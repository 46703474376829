import { all, fork, put, select, takeEvery } from "redux-saga/effects";

// Own
import apiRequest from "../../helpers/apiHelper";
import {
  GET_REACTIONS,
  REACTED_TO
} from "../constants";
import {
  getReactionsError,
  getReactionsSuccess,
  reactedToError,
  reactedToSuccess
} from "./actions";


export function* reactedTo({payload}) {
  const state = yield select();
  const loading = state?.reactions?.loading;
  if (!loading) {
    return;
  }

  try {
    const request = yield apiRequest({
      "api": "ReactedTo",
      "props": payload
    });

    if (request.status){
      yield put(reactedToSuccess(request.response))
    }else{
      yield put(reactedToError(request.Error.errorCode))
    }

  } catch (error) {
    yield put(reactedToError(error))
  }
}

export function* getReactions({payload}) {
  const state = yield select();
  const loading = state?.reactions?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      "api": "GetReactions",
      "props": payload
    });

    if (request.status){
      yield put(getReactionsSuccess(request.response))
    }else{
      yield put(getReactionsError(request.Error.errorCode))
    }

  } catch (error) {
    yield put(reactedToError(error))
  }
}

export function* watchReactedTo() {
  yield takeEvery(REACTED_TO, reactedTo)
}

export function* watchGetReactions() {
  yield takeEvery(GET_REACTIONS, getReactions)
}

export default function* rootSaga() {
  yield all([
    fork(watchReactedTo),
    fork(watchGetReactions),
  ]);
}
