import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';
import {
  TO_SUBSCRIBE,
} from '../constants';
import {
  toSubscribeSuccess,
  toSubscribeError,
} from './actions';

const toSubscribeRequest = async (payload) => {
  // eslint-disable-next-line no-return-await
  return await apiRequest({
    api: 'SaveEcosystemSubscribersData',
    props: {
      subscription: payload
    },
  })
  .then((response)=> response)
  .catch((error) => error);
}

function* toSubscribe({payload}) {
  const state = yield select();
  const loading = state?.preferences?.loading;
  if (!loading) {
    return;
  }
  try {
    const response = yield call(toSubscribeRequest, payload);
    if (response.status) {
      yield put(toSubscribeSuccess());
    }
    if (!response.status) {
      yield put(toSubscribeError('Error'));
    }
  } catch (error) {
    yield put(toSubscribeError(error));
  }
}

export function* watchToSubscribe() {
  yield takeEvery(TO_SUBSCRIBE, toSubscribe);
}

export default function* rootSaga() {
  yield all([
    fork(watchToSubscribe),
  ]);
}
