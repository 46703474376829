import {
  REACTED_TO,
  REACTED_TO_SUCCESS,
  REACTED_TO_ERROR,
  GET_REACTIONS,
  GET_REACTIONS_SUCCESS,
  GET_REACTIONS_ERROR
} from '../constants';

export const reactedTo = (payload) => ({
  type: REACTED_TO,
  loading: true,
  payload,
});

export const reactedToSuccess = (payload) => ({
  type: REACTED_TO_SUCCESS,
  loading: false,
  payload,
});

export const reactedToError = (payload) => ({
  type: REACTED_TO_ERROR,
  loading: false,
  payload,
});

export const getReactions = (payload) => ({
  type: GET_REACTIONS,
  loading: true,
  payload,
});

export const getReactionsSuccess = (payload) => {
  return {
    type: GET_REACTIONS_SUCCESS,
    loading: false,
    payload,
  }
};

export const getReactionsError = (payload) => ({
  type: GET_REACTIONS_ERROR,
  loading: false,
  payload,
});
