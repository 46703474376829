import {
  CONTACT,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
} from '../constants';

export const contact = ( captchaValue ) => ({
  type: CONTACT,
  payload: captchaValue,
});
export const contactSuccess = (items) => ({
  type: CONTACT_SUCCESS,
  payload: items,
});
export const contactError = (error) => ({
  type: CONTACT_ERROR,
  payload: error,
});
