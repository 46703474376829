import {
  all,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

// Own
import apiRequest from '../../helpers/apiHelper';
import { voteAPostSuccess, voteAPostError } from './actions';

import { VOTE_A_POST } from '../constants';

export function* voteAPost({payload}) {
  const state = yield select();
  const loading = state?.votes?.loading;
  if (!loading) {
    return;
  }

  try {
    const request = yield apiRequest({
      "api": "VoteAPost",
      "props": payload
    });

    if (request.status){
      yield put(voteAPostSuccess(request.response))
    }else{
      yield put(voteAPostError(request.Error.errorCode))
    }

  } catch (error) {
    yield put(voteAPostError(error))
  }
}


export function* watchVoteAPost() {
  yield takeEvery(VOTE_A_POST, voteAPost);
}

export default function* rootSaga() {
  yield all([
    fork(watchVoteAPost),
  ]);

}
