import {
  all,
  fork,
  put,
  select,
  takeEvery
} from "redux-saga/effects";
// Import of globals helpers
import apiRequest from "../../helpers/apiHelper";
// Import of globals constants
import {
  ROLE_LIST
} from '../constants';
// This are actions of saga
import {
  getRoleListError,
  getRoleListSuccess
} from "./action";

export function* getRolesList({ payload }) {
  const state = yield select();
  const loading = state?.roles?.loading;
  if (!loading) {
    return;
  }
  try {
    const request = yield apiRequest({
      api: 'GetRolesList',
      props: null,
    });

    if (request.status) {
      yield put(getRoleListSuccess(request.response));
    } else {
      yield put(getRoleListError(request.Error.errorCode));
    }
  } catch (error) {
    yield put(getRoleListError(error));
  }
}

export function* watchGetRolesList() {
  yield takeEvery(ROLE_LIST, getRolesList);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRolesList),
  ]);
}
