import { redirect } from 'react-router-dom';

// Own
import { ROUTE_LOGIN } from '../constants/routes';
import { getToken } from '../helpers/session-utils';


const LoginGuard = ({user}) => {
  const token = getToken();
  if (!user && !token) {
    return redirect(ROUTE_LOGIN);
  }
  return null;
}

export default LoginGuard
