import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_SOCIAL,
  LOGIN_USER_SOCIAL_SUCCESS,
  LOGIN_USER_SOCIAL_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  LOAD_USER_INFO,
  LOAD_USER_INFO_SUCCESS,
  LOAD_USER_INFO_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  CLEAR_STATUS,
} from '../constants';

const INIT_STATE = {
  loading: false,
  error: null,
  attempts: 0,
  user: null,
  status: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // BASIC LOGIN
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        status: false,
        error: null
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        error: null,
        attempts: 0
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        status: false,
        error: action.payload.message,
        attempts: ++state.attempts
      };

    // LOGIN WITH SOCIAL
    case LOGIN_USER_SOCIAL:
      return {
        ...state,
        loading: true,
        status: false,
        error: null
      };

    case LOGIN_USER_SOCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        error: null,
        attempts: 0
      };

    case LOGIN_USER_SOCIAL_ERROR:
      return {
        ...state,
        loading: false,
        status: false,
        error: action.payload.message,
        attempts: ++state.attempts
      };

    // AUTH REGISTER
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: null,
        status: false,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: true,
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: false,
      }

    // AUTH FORGOT PASSWORD
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    // AUTH UPDATE PASSWORD
    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      }

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }

    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // AUTH LOAD USER INFORMATION
    case LOAD_USER_INFO:
      return {
        ...state,
        loading: true,
      }
    case LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      }
    case LOAD_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      }

    // AUTH LOGOUT
    case LOGOUT:
      return {
        ...state,
        loading: true,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        error: null,
      }
    case LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      }

    case CLEAR_STATUS:
      return {
        ...state,
        loading: false,
        error: null,
        attempts: 0,
        user: null,
        status: false,
      };
    // DEFAULT
    default:
      return { ...state };
  }
};

export default reducer;
